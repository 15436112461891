import React from 'react';
import bem from 'easy-bem';
import { Collapse } from 'antd';
import NeedHelp from '../../../ui-kit/needHelp';
import './style.scss';


const { Panel } = Collapse;

function Help() {
  const b = bem('help');

  return (
    <div className={b()}>
      <h2 className={b('top-main')}>
        <h2 className={b('title-main')}>Помощь</h2>
      </h2>

      <Collapse className={b('collapse')}>
        <Panel key="1" header="Что такое Poizon?">
          <p className={b('text-small')}>
            Poizon (毒) - это китайское мобильное приложение и онлайн-платформа, специализирующаяся на перепродаже
            ограниченных
            и эксклюзивных товаров, преимущественно кроссовок и уличной одежды. Приложение было запущено
            в 2015 году и быстро завоевало популярность среди любителей редких и коллекционных вещей.
          </p>
        </Panel>
        <Panel key="2" header="Что такое Poizon?">
          <p className={b('text-small')}>
            Poizon (毒) - это китайское мобильное приложение и онлайн-платформа, специализирующаяся на перепродаже
            ограниченных
            и эксклюзивных товаров, преимущественно кроссовок и уличной одежды. Приложение было запущено
            в 2015 году и быстро завоевало популярность среди любителей редких и коллекционных вещей.
          </p>
        </Panel>
        <Panel key="3" header="Что такое Poizon?">
          <p className={b('text-small')}>
            Poizon (毒) - это китайское мобильное приложение и онлайн-платформа, специализирующаяся на перепродаже
            ограниченных
            и эксклюзивных товаров, преимущественно кроссовок и уличной одежды. Приложение было запущено
            в 2015 году и быстро завоевало популярность среди любителей редких и коллекционных вещей.
          </p>
        </Panel>
        <Panel key="4" header="Что такое Poizon?">
          <p className={b('text-small')}>
            Poizon (毒) - это китайское мобильное приложение и онлайн-платформа, специализирующаяся на перепродаже
            ограниченных
            и эксклюзивных товаров, преимущественно кроссовок и уличной одежды. Приложение было запущено
            в 2015 году и быстро завоевало популярность среди любителей редких и коллекционных вещей.
          </p>
        </Panel>

      </Collapse>
      <div className={b('help-form')}>
        <NeedHelp />
      </div>
    </div>
  );
}

export default Help;
