import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import bem from 'easy-bem';

import './style.scss';
import { useNavigate } from 'react-router-dom';
import { getCatalogFiltered } from '../../models/catalog/catalogSlice';
import { categoryNames } from '../../utils/formatCategory';
import Card from '../../ui-kit/card';


function SpecialOffers({ category }) {
  const b = bem('special');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await dispatch(getCatalogFiltered(`limit=9&category=${category}&ordering=-score`));
        setData(result.payload.results);
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    };

    fetchData();
  }, [category, dispatch]);

  const goToCatalog = () => {
    navigate('/catalog', { state: { category } });
  };

  if (data && data.length === 0) {
    return null;
  }

  return (
    <div className={b()}>
      <div className={b('info')}>
        <h2 className={b('category-name')}>{categoryNames[category] || category}</h2>
        <p onClick={goToCatalog} className={b('see-more')}>Смотреть все</p>
      </div>
      <div className={b('content')}>
        {data && data.map((item, index) => (
          <Card key={index} item={item} />
        ))}
      </div>
    </div>
  );
}

export default SpecialOffers;
