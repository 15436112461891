import React, { useRef, useState, useEffect } from 'react';
import { Form } from 'antd';
import bem from 'easy-bem';
import { useSelector, useDispatch } from 'react-redux';
import ModalV2 from '../../ui-kit/modal';
import { ReactComponent as Telegram } from '../../assets/icons/Telegram Fill.svg';
import { ReactComponent as Whatsapp } from '../../assets/icons/Whatsapp.svg';
import ButtonCustom from '../../ui-kit/button';

import './style.scss';
import InputCustom from '../../ui-kit/input';
import { checkAuthTelegram, createTelegramToken, fetchAuth } from '../../models/user/userSlice';
import ResetPassword from '../resetPassword';
import { getToken } from '../../utils/token';


// для регистрации
function Login({
  isOpen, onClose, setIsModalRegister, navigate, targetRoute, test
}) {
  const b = bem('login');
  const dispatch = useDispatch();
  const token = getToken();


  const { telegramToken } = useSelector((state) => state.user);
  const [checking, setChecking] = useState(true);
  const [tries, setTries] = useState(0);

  const handleRegister = () => {
    // formRef.current.resetFields();
    onClose();
    setIsModalRegister(true);
  };

  const handleClose = () => {
    onClose();
    // formRef.current?.resetFields();
  };


  useEffect(() => {
    // if (navigate && targetRoute) {
    //   navigate();
    //   handleClose();
    // } else {
    //   // test();
    //   handleClose();
    // }
    handleClose();
  }, [token]);


  const handleAuth = async (tg) => {
    try {
      const newWindow = window.open('', '_blank')
      const action = await dispatch(createTelegramToken());
      const { token } = action.payload;

      const url = tg
        ? `https://t.me/TruePoisonRussiaBot?start=${token}`
        : `https://wa.me/79192189331?text=Войти%20UUID%20${token}`;

      // window.open(url, '_blank'); // Открываем в новой вкладке
      if (newWindow) {
        newWindow.location.href = url;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let intervalId;

    const checkRegistration = async () => {
      if (!checking || tries === 18) { // 18 * 10 секунд = 3 минуты
        clearInterval(intervalId);

        return;
      }

      if (telegramToken) {
        dispatch(checkAuthTelegram({ token: telegramToken }))
          .then((result) => {
            if (result.payload.access_token) {
              setChecking(false); // Останавливаем проверку после получения access token
            } else {
              setTries((prevTries) => prevTries + 1); // Увеличиваем количество попыток
            }
          })
          .catch(() => {
            setTries((prevTries) => prevTries + 1); // Увеличиваем количество попыток при ошибке
          });
      }
    };

    intervalId = setInterval(checkRegistration, 5000); // Проверка каждые 10 секунд

    return () => clearInterval(intervalId); // Очистка интервала при размонтировании компонента
  }, [telegramToken, checking, tries, dispatch]);

  return (
    <>
      <ModalV2
        className={b()}
        open={isOpen}
        onClose={handleClose}
        title="Вход в личный кабинет"
        footer={null}
      >
            <div className={b('modal-btn')}>
              {/* <ButtonCustom className={b('btn')} type="primary" htmlType="submit">Войти</ButtonCustom> */}
              <ButtonCustom className={b('btn-tg')} type="primary-light" icon={<Telegram />} onClick={() => handleAuth(true)}>Войти через Telegram</ButtonCustom>
              <ButtonCustom className={b('btn-tg')} type="primary-green" icon={<Whatsapp />} onClick={() => handleAuth(false)}>Войти через WhatsApp</ButtonCustom>
            </div>

            <ButtonCustom className={b('registration')} type="text" onClick={() => handleRegister()}>Зарегистрироваться</ButtonCustom>

      </ModalV2>
    </>
  );
}

export default Login;
