import React, { useState } from 'react';
import bem from 'easy-bem';
import '../style.scss';

const MobilePopup = ({ data }) => {
  const b = bem('header');
  const [currentComponent, setCurrentComponent] = useState(null);

  const handleClick = (component) => {
    setCurrentComponent(component);
  };

  const handleBackClick = () => {
    setCurrentComponent(null);
  };

  return (
    <div className={b('mobile-popup')}>
      {currentComponent ? (
        <div className={b('submenu')}>
          <button className={b('back-button')} onClick={handleBackClick}>
            Назад
          </button>
          {currentComponent}
        </div>
      ) : (
        <ul>
          {data.map((item) => (
            <li key={item.key} className={b('link-mobile')} onClick={() => handleClick(item.component)}>
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MobilePopup;
