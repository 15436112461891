import React from 'react';
import bem from 'easy-bem';
import './style.scss';
import { formatPrice } from '../../utils/formatPrice';


function OrderProductItem({ item, count }) {
  const b = bem('order-product-item');

  console.log(item, 'FROM OIJDWOWO');
  // console.log(item.properties.length, 'FROM OIJDWOWO');

  return (
    <div className={b()}>
      <div className={b('img-container')}>
        <img src={item.images[0].link} alt="img" className={b('img')} />
      </div>

      <div className={b('content')}>
        <div className={b('info')}>
          <div className={b('name-size')}>
            <h3 className={b('name')}>{item.name}</h3>
            <p className={b('size')}>
              <span className={b('sub-size')}>Размер:</span>
              {' '}
              {item.properties.length === 1 ? item.properties[0].value : item.properties[1].value}
            </p>
          </div>
          <p className={b('price')}>
            {formatPrice(item.price)} ₽
          </p>
        </div>

        <div className={b('count-box')}>
          <p className={b('count')}>{count}</p>
        </div>
      </div>
    </div>
  );
}
//TODO какая то хрень с размером для аксессуаров
export default OrderProductItem;
