import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import bem from 'easy-bem';

import './style.scss';
import { Form, message, Spin } from 'antd';
import { AddressSuggestions } from 'react-dadata';
import InputCustom from '../../ui-kit/input';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { requiredQuill, rulesValidationEmail } from '../../utils/validation';
import ButtonCustom from '../../ui-kit/button';
import BasketItem from '../../ui-kit/basketItem';
import OrderProductItem from '../../ui-kit/orderProductItem';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { formatAddress } from '../../utils/formatAddress';
import { pluralize } from '../../utils/pluralize';
import { formatPrice } from '../../utils/formatPrice';
import { getStatusDetails } from '../orderList/data';
import { getPaymentLink, getSingleOrder } from '../../models/order/orderSlice';
import CustomTabs from '../../ui-kit/tabs';

const OrderFullInfo = () => {
  const b = bem('order-full-info');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [order, setOrder] = useState(null)
  const [addressValue, setAddressValue] = useState('')
  const { color, translation } = getStatusDetails(order?.status);

  useEffect(() => {
    const getOrder = async () => {
      try {
        const response = await dispatch(getSingleOrder(id));

        console.log(response);
        setOrder(response.payload);
      } catch (e) {
        message.error('Что-то пошло не так...');
        navigate(-1);
      }
    }
    getOrder();
  }, [id]);
  console.log(order);
  const handleClose = () => {
    // onClose();
    navigate('/orders');
  }
  const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      message.success('Текст скопирован!');
    }).catch(err => {
      message.error('Не удалось скопировать текст');
    });
  };


  const deliveryType = () => {
    switch (order.delivery_type) {
      case 'COURIER':
        return (
          <div className={b('delivery-box')}>
            <div className={b('d-top')}>
              <h3 className={b('d-title')}>Доставка</h3>
              {order.is_express_delivered && <p className={b('express')}>Экспресс-доставка</p>}
            </div>
            <p className={b('d-type')}>Курьер</p>
            <InputCustom
              name="delivery_address"
              className={b('input-delivery')}
              label="Адрес"
              placeholder={order.address}
              type="text"
              disabled
            />

            <InputCustom
              name="city"
              className={b('input-delivery')}
              label="Город"
              placeholder={order.city}
              type="text"
              disabled
            />
          </div>
        );
      case 'CDEK':
        return (
          <div className={b('delivery-box')}>
            <div className={b('d-top')}>
              <h3 className={b('d-title')}>Доставка</h3>
              {order.is_express_delivered && <p className={b('express')}>Экспресс-доставка</p>}
            </div>
            <p className={b('d-type')}>СДЭК</p>
            <InputCustom
              name="city"
              className={b('input-delivery')}
              label="Город"
              placeholder={order.city}
              type="text"
              disabled
            />
            <InputCustom
              name="cdek_address"
              className={b('input-delivery')}
              label="Пункт СДЭК"
              placeholder={formatAddress(order.cdek_address)}
              type="text"
              disabled
            />
          </div>
        )
      case 'PICKUP':
        return (
          <div className={b('delivery-box')}>
            <div className={b('d-top')}>
              <h3 className={b('d-title')}>Доставка</h3>
              {order.is_express_delivered && <p className={b('express')}>Экспресс-доставка</p>}
            </div>

            <p className={b('d-type')}>Самовывоз</p>
            <div className={b('pick-up-box')}>
              <InputCustom
                name="cdek_address"
                className={b('input-delivery')}
                label="Адрес самовывоза"
                placeholder='Г. Курск. Проспект Победы Д.26'
                type="text"
                disabled
              />
              <ButtonCustom className={b('copy-btn')} type='icon' icon={<Copy />} onClick={() => copyToClipboard('Г. Курск. Проспект Победы Д.26')} />
            </div>
          </div>
        )
      default:
        return null;
    }
  }

  const handleSplitPay = async () => {
    try {
      const link = await dispatch(getPaymentLink(order.id));
      if (!link.error){
        window.open(link.payload, '_blank');
        // navigate('/orders', { state: 'open-last' });
      }
      if (link.error){
        message.error('Что-то пошло не так. Попробуйте снова.');
      }
      console.log(link);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className={b()}>
      <div className={b('menu')}>
        <CustomTabs/>
      </div>
      {!order ? <div className={b('spin')}><Spin size='large'/></div> :
        <div className={b('box')}>
          <div className={b('top')}>
            <div className={b('top-info')}>
              <h2 className={b('order-id')}>Заказ {order.id}</h2>
              <p className={b('status')} style={{ backgroundColor: color }}>{translation}</p>
            </div>
            <ButtonCustom
              onClick={handleClose}
              type="text"
              className={b('btn-back')}
            >Назад</ButtonCustom>
          </div>
          <div className={b('content')}>
            <div className={b('left')}>
              <div className={b('forms')}>
                {deliveryType()}
                <div className={b('user-info')}>
                  <h2 className={b('u-title')}>Получатель</h2>
                  <Form name="user-info">
                    <InputCustom
                      name="full_name"
                      label="ФИО"
                      placeholder={order.user_full_name}
                      className={b('user-input')}
                      // rules={[requiredQuill]}
                      value={order.user_full_name}
                      disabled
                    />
                    <InputCustom
                      name="email"
                      label="E-mail"
                      placeholder={order.user_email}
                      className={b('user-input')}
                      rules={[rulesValidationEmail, requiredQuill]}
                      value={order.user_email}
                      disabled
                    />
                    <InputCustom
                      name="phone"
                      label="Номер телефона"
                      placeholder={formatPhoneNumber(order.user_phone)}
                      className={b('user-input')}
                      value={order.phone}
                      disabled
                    />
                  </Form>
                </div>
              </div>
              <div className={b('list')}>
                <h3 className={b('list-title')}>Состав заказа</h3>
                <OrderProductItem item={order.item_sku} count={order.count}/>
              </div>
            </div>

            <div className={b('count-price')}>
              <div className={b('total')}>
                <p className={b('total-count')}>
                  Всего:
                  {' '}
                  {order?.count}
                  {' '}
                  {pluralize(order?.count, 'товар', 'товара', 'товаров')}
                </p>
                <p className={b('total-price')}>{`${formatPrice(order.total_cost_without_promo_code_and_bonuses)} ₽`}</p>
              </div>
              {order && order?.bonuses !== null && order?.bonuses !== 0  && (
                <>
                  <div className={b('benefit')}>
                    <p className={b('b-title')}>Выгода</p>
                    <p className={b('b-count')}>
                      - {order.bonuses}
                      {' '}
                      ₽
                    </p>
                  </div>
                  <div className={b('points')}>
                    <li className={b('p-title')}>Баллы</li>
                    <p className={b('p-count')}>
                      - {order.bonuses}
                      {' '}
                      ₽
                    </p>
                  </div>
                </>
              )}
              {order && order?.cost_delivered !== 0 && (
                <>
                  <div className={b('benefit')}>
                    <p className={b('b-title')}>Доставка</p>
                    <p className={b('b-count')}>
                      + {order?.cost_delivered}
                      {' '}
                      ₽
                    </p>
                  </div>
                  <div className={b('points')}>
                    <li className={b('p-title')}>Доставка</li>
                    <p className={b('p-count')}>
                      + {order?.cost_delivered}
                      {' '}
                      ₽
                    </p>
                  </div>
                </>
              )}
              {order && order?.promo_code_discount !== null && order?.promo_code_discount !== 0  && (
                <>
                  <div className={b('benefit')}>
                    <p className={b('b-title')}>Скидка</p>
                    <p className={b('b-count')}>
                      - {order.promo_code_discount} %
                    </p>
                  </div>
                  <div className={b('points')}>
                    <li className={b('p-title')}>Промокод</li>
                    <p className={b('p-count')}>
                      - {order.promo_code_discount} %
                    </p>
                  </div>
                </>
              )}
              {order.is_split && (
                <>
                  <p className={b('total-split')}>
                    Покупка в сплит
                  </p>
                  <>
                    <div className={b('benefit')}>
                      <p className={b('b-title')}>Сплит</p>
                      <p className={b('b-count')}>
                        {order.cost_delivered > 0 ? formatPrice(parseInt(order.total_cost) + order.cost_delivered) : formatPrice(order.total_cost)}
                        {' '}
                        ₽
                      </p>
                    </div>
                    <div className={b('points')}>
                      <li className={b('p-title')}>Первый платёж</li>
                      <p className={b('p-count')}>
                        {order.cost_delivered > 0 ? formatPrice(Math.ceil(order.total_cost / 2) + order.cost_delivered) : formatPrice(Math.ceil(order.total_cost / 2))}
                        {' '}
                        ₽
                      </p>
                    </div>
                    <div className={b('points')}>
                      <li className={b('p-title')}>Второй платёж</li>
                      <p className={b('p-count')}>
                        {formatPrice(Math.floor(order.total_cost / 2))}
                        {' '}
                        ₽
                      </p>
                    </div>
                  </>
                </>
              )}
              {/* {order.is_split && order.status === 'AWAITING_SPLIT_PAID' && */}
              {order.is_split &&
                <ButtonCustom
                  type="link"
                  className={b('pay')}
                  onClick={handleSplitPay}
                  // disabled={isSubmitting}
                >
                  Оплатить вторую часть
                  {/* {isSubmitting && !splitHandle ? <Spin className={b('spin')} /> : 'Оплатить'} */}
                </ButtonCustom>}
              <div className={b('sum')}>
                <p className={b('s-title')}>Итого:</p>
                <p className={b('s-count')}>
                  {/* {order.is_split ? Math.ceil((parseInt(order.total_cost) + order.cost_delivered) / 2) : ''} */}
                  {order.cost_delivered > 0 ? formatPrice(parseInt(order.total_cost) + order.cost_delivered) : formatPrice(order.total_cost)} ₽
                </p>
              </div>
            </div>
          </div>
        </div>
      }

    </div>
  );
};

export default OrderFullInfo;
//TODO что за formatAddress
