import React, { useEffect, useState } from 'react';
import bem from 'easy-bem';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Popover } from 'antd';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as LogoIconWhite } from '../../assets/icons/logo-mobile.svg';
import { ReactComponent as Search } from '../../assets/icons/search-black.svg';
import { ReactComponent as Cart } from '../../assets/icons/shopping-cart.svg';
import { ReactComponent as Favorites } from '../../assets/icons/heart.svg';
import { ReactComponent as User } from '../../assets/icons/user.svg';
import { ReactComponent as Menu } from '../../assets/icons/menu.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/x.svg';
import './style.scss';
import ButtonCustom from '../../ui-kit/button';
// import Basket from '../basket';
import useAuthCheck from '../../utils/useAuthCheck';
import AuthModals from '../authModals';
import { getBrands, getCategories } from '../../models/catalog/catalogSlice';
import PopUp from '../popUp';
import SearchHeader from '../searchHeader';
import MobilePopup from './mobilePopup/component';
import CustomTabs from '../../ui-kit/tabs';
import { categoriesArray } from './data';


function Header() {
  const b = bem('header');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { brands } = useSelector((state) => state.catalog);
  // const [isBasketOpen, setIsBasketOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isPopUpMenuOpen, setIsPopUpMenuOpen] = useState(false);

  const [isFixed, setIsFixed] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const {
    isModalOpen,
    isModalRegister,
    isModalLogin,
    warningTitle,
    checkAuth,
    handleRegister,
    handleLogin,
    handleClose,
    targetRoute,
  } = useAuthCheck();

  useEffect(() => {
    dispatch(getBrands('?category=footwear'));
    dispatch(getCategories());
  }, [dispatch]);

  const menuItems = [
    { label: 'Обувь', key: '1', component: <PopUp data={categoriesArray[0]} onClose={() => isMobileMenuOpen && setIsMobileMenuOpen(false)} /> },
    { label: 'Одежда', key: '2', component: <PopUp data={categoriesArray[1]} onClose={() => isMobileMenuOpen && setIsMobileMenuOpen(false)} /> },
    { label: 'Аксессуары', key: '3', component: <PopUp data={categoriesArray[2]} onClose={() => isMobileMenuOpen && setIsMobileMenuOpen(false)} /> },
    { label: 'Бренды', key: '4', component: <PopUp data={brands} brands onClose={() => isMobileMenuOpen && setIsMobileMenuOpen(false)} /> }
  ];

  useEffect(() => {
    if (isSearchOpen || isMobileMenuOpen || isProfileMenuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll'); // Очистка при размонтировании
    };
  }, [isSearchOpen, isMobileMenuOpen, isProfileMenuOpen]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isProfileMenuOpen, isSearchOpen, isMobileMenuOpen]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollY = window.scrollY;
  //
  //     if (window.innerWidth <= 649) {
  //       setIsFixed(currentScrollY > 50);
  //       setIsHidden(currentScrollY > scrollY);
  //     }
  //
  //     setScrollY(currentScrollY);
  //   };
  //
  //   window.addEventListener('scroll', handleScroll);
  //
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [scrollY]);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (window.innerWidth <= 649) {
        if (currentScrollY > lastScrollY) {
          setIsHidden(true);  // Прокрутка вниз - скрываем меню
        } else {
          setIsHidden(false); // Прокрутка вверх - показываем меню
        }

        setIsFixed(currentScrollY > 50); // Фиксация меню после 50px
      }

      lastScrollY = currentScrollY > 0 ? currentScrollY : 0; // Обновляем позицию
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  return (
    <>
      <div
        className={`${b()}  ${isFixed ? 'fixed' : ''} ${isHidden ? 'hidden' : ''} ${(isMobileMenuOpen || isProfileMenuOpen) && 'open-mobile-menu'} ${isSearchOpen && 'open-mobile-search'}`}>
        <Link to="/" onClick={() => setIsSearchOpen(false)}>
          {isMobileMenuOpen || isProfileMenuOpen ? <LogoIconWhite className={b('logo-mobile')}/> : <LogoIcon/>}
        </Link>

        <ul className={b('links')}>
          {menuItems.map((item) => (
            <Popover
              key={item.key}
              content={item.component}
              autoAdjustOverflow
              arrow={false}
              // visible={true}
              // onOpenChange={han}
              // onVisibleChange={handleVisibleChange}
            >
              <li key={item.key} className={b('link')}>{item.label}</li>
            </Popover>
          ))}
        </ul>
        {isMobileMenuOpen && <div className={b('mobile-links')}><MobilePopup data={menuItems}/></div>}

        <div className={b('icons')}>
          <ButtonCustom
            type="icon"
            icon={<Search className={isSearchOpen ? b('search-icon blue') : b('search-icon white')}/>}
            onClick={() => {
              setIsSearchOpen(!isSearchOpen);
              setIsMobileMenuOpen(false);
            }}
          />
          <ButtonCustom
            className={b('desktop-icon')}
            type="icon"
            icon={<Favorites/>}
            onClick={() => {
              checkAuth('/favorites', 'Чтобы добавить товар в избранное');
              setIsSearchOpen(false);
            }}
          />
          <ButtonCustom
            className={b('desktop-icon')}
            type="icon"
            icon={<User/>}
            onClick={() => {
              checkAuth('/profile', 'Чтобы войти в личный кабинет');
              setIsSearchOpen(false);
            }}
          />
          <ButtonCustom
            className={b('menu-icon')}
            type="icon"
            icon={isMobileMenuOpen || isProfileMenuOpen ? <CloseIcon/> : <Menu/>}
            onClick={() => {
              if (isProfileMenuOpen) {
                setIsProfileMenuOpen(false);
              } else {
                setIsMobileMenuOpen(!isMobileMenuOpen);
                setIsSearchOpen(false);
              }
            }}
          />
        </div>

        <AuthModals
          isModalOpen={isModalOpen}
          isModalRegister={isModalRegister}
          isModalLogin={isModalLogin}
          warningTitle={warningTitle}
          handleClose={handleClose}
          handleRegister={handleRegister}
          handleLogin={handleLogin}
          navigate={navigate}
          targetRoute={targetRoute}
        />
      </div>
      <div className={b('mobile-bottom-menu')}>
        <ButtonCustom
          className={b('mobile-icon')}
          type="icon"
          icon={<Favorites/>}
          onClick={() => {
            checkAuth('/favorites', 'Чтобы добавить товар в избранное');
            setIsSearchOpen(false);
            setIsMobileMenuOpen(false);
          }}
        />
        <ButtonCustom
          className={b('mobile-icon')}
          type="icon"
          icon={<User/>}
          onClick={() => {
            checkAuth(() => {
              setIsProfileMenuOpen(!isProfileMenuOpen);
            }, 'Чтобы войти в личный кабинет');
            setIsSearchOpen(false);
            setIsMobileMenuOpen(false);
          }}
        />
      </div>
      {isSearchOpen && (
        <SearchHeader
          onClose={() => setIsSearchOpen(false)}
        />
      )}
      {isProfileMenuOpen && (
        <CustomTabs onClose={() => setIsProfileMenuOpen(false)}/>
      )}
    </>
  );
}

export default Header;
