import React, { useEffect, useState } from 'react';
import bem from 'easy-bem';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation, useNavigate, useParams
} from 'react-router-dom';

import './style.scss';
import { Image, Carousel, Spin } from 'antd';
import ButtonCustom from '../../ui-kit/button';
import { ReactComponent as Like } from '../../assets/icons/heart-like.svg';
import NeedHelp from '../../ui-kit/needHelp';
import {
  addFavoriteItem,
  deleteFavoriteItem,
  getProductById, setProduct as setProductItem
} from '../../models/catalog/catalogSlice';
import { formatPrice } from '../../utils/formatPrice';
import AuthModals from '../authModals';
import useAuthCheck from '../../utils/useAuthCheck';
import SpecialOffers from '../specialOffers';


function ProductPage() {
  const b = bem('product-page');
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { product: productFromLink } = useSelector((state) => state.catalog);
  const breadCrumbItem = location?.state?.categoryOrBrand || null;
  const fromSearch = location?.state?.fromSearchByLink || false;
  const [product, setProduct] = useState(null);
  const [activeTab, setActiveTab] = useState('eu');
  const [activeTabInfo, setActiveTabInfo] = useState('Описание');
  const [choosenSize, setChoosenSize] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [currentSku, setCurrentSku] = useState([]);
  const [isFavorite, setIsFavorite] = useState();
  const [selectedImage, setSelectedImage] = useState('');
  const sizeOnly = [];

  const {
    isModalOpen,
    isModalRegister,
    isModalLogin,
    warningTitle,
    checkAuth,
    handleRegister,
    handleLogin,
    handleClose,
  } = useAuthCheck();


  useEffect(() => {
    if (currentSku?.images?.length > 0) {
      setSelectedImage(currentSku.images[0].link);
    }
  }, [currentSku]);

  const fetchProduct = async () => {
    try {
      if (productFromLink) {
        setProduct(productFromLink);
        dispatch(setProductItem([]));
      } else {
        const result = await dispatch(getProductById(id));
        setProduct(result.payload);
      }
    } catch (e) {
      console.log(e);
    }
  }


  useEffect(() => {
    setProduct(null);
    fetchProduct();
  }, [id]);

  const sizeParsing = (skus, type) => {

    const filteredSkus = skus.filter((sku) => {
      // console.log(sku);
      if (sku.size !== null && sku.size[type]) {
        // console.log('here');
        return true; // Если есть размер типа type, то оставляем sku
      } else if (sku.properties) {

        const hasSizeType = Object.values(sku.properties).some(prop => {
          return (prop.type === 1 || prop.type === 4042) && prop.value === type;
        });

        // console.log(hasSizeType);
        return hasSizeType;
      }
      return false;
    });

    const sortedSkus = filteredSkus.sort((a, b) => {
      // Функция для преобразования размера в число или строку
      if (a.size === null || b.size === null) {
        return;
      }
      const parseSize = (size) => {
        if (typeof size === 'number') return size;

        // Если size не определен или не является строкой, вернем его как есть
        if (typeof size !== 'string') return size;

        // Преобразуем размер в строку и заменим возможные дробные символы
        const normalizedSize = size.replace('⅔', '.67')
          .replace('½', '.5')
          .replace('⅓', '.33');

        // Попробуем преобразовать в число, если не получится - вернем оригинальное значение
        const parsed = parseFloat(normalizedSize);
        return isNaN(parsed) ? size : parsed;
      };

      const aSize = parseSize(a.size[type]);
      const bSize = parseSize(b.size[type]);

      // Если оба значения - числа, сравниваем их
      if (typeof aSize === 'number' && typeof bSize === 'number') {
        return aSize - bSize;
      }

      // Если одно из значений не число, сравниваем как строки
      return String(aSize).localeCompare(String(bSize));
    });

    return sortedSkus.map((sku) => ({
      size: sku.size !== null && sku.size[type] || (sku.properties.find(prop => prop.type === 6 || prop.type === 2735 || prop.type === 13)?.value),
      price: sku.price,
      type,
      sku
    }));
  };


  const getUniqueSizeTypes = (skus, sizes) => {
    const sizeTypes = new Set();

    skus.forEach((sku) => {
      // if (Object.keys(sku?.size ?? {}).length === 0) { // вот так было
      // if (Object.keys(sku?.size ?? {}).length === 0 && sizeTypes.size === 0) { // было сделано, чтобы убрать "Белое"
      if ((Object.keys(sku?.size ?? {}).length === 0 || sku?.size.hasOwnProperty('size')) && (sizes.length === 0 || sizes[0].type === 'size')) { // чтобы чтобы избежать попадания цветов
        Object.keys(sku?.properties ?? {}).forEach((type) => {
          if ((sku.properties[type].type === 1 || sku.properties[type].type === 4042) && sku.properties[type].value !== 'primary') {
            sizeTypes.add(sku.properties[type].value);
          }
        });
      }

      Object.keys(sku?.size ?? {}).forEach((type) => {
        if (type !== 'primary' && type !== 'size') {
          sizeTypes.add(type);
        }
      });
    });

    return Array.from(sizeTypes);
  };

  const sizeTypes = getUniqueSizeTypes(product?.skus || [], product?.sizes);

  const tabs = product?.category.length !== 0 ? sizeTypes.map((type) => ({
    key: type,
    title: type,
    content: sizeParsing(product?.skus, type),
  })) : [];

  useEffect(() => {
    setSelectedImage(product?.skus[0]?.images[0].link);
    setActiveTabInfo('Описание');
    setIsFavorite(product?.is_favorited);

    if (tabs[0]?.content) {
      setActiveTab(tabs[0]['key']);
      setChoosenSize(0);
      setCurrentPrice(tabs[0].content[0].price);
      setCurrentSku(tabs[0].content[0]);
    }
    if (tabs.length === 0 && sizeOnly.length !== 0) {
      setActiveTab(sizeOnly[0].content.id);
      setCurrentPrice(sizeOnly[0].content.price);
      setCurrentSku(sizeOnly[0].content);
    }
  }, [product]);

  if (!product) {
    return <div className={b('loading-box')}><Spin className={b('spin')} size="large"/></div>;
  }

  const handleItemClickSize = (index, item) => {
    setChoosenSize(index);
    setCurrentPrice(item.price);
    setCurrentSku(item);
  };

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  const tabsInfo = [
    { key: 'Описание', title: 'Описание', content: product },
    // { key: 'Детали', title: 'Детали', content: product },
    // { key: 'Доставка', title: 'Доставка', content: product },
    // { key: 'Информация', title: 'Информация', content: product },
  ];

  const changeTabSize = (item) => {
    setActiveTab(tabs.length === 0 ? item.id : item.key);
    setChoosenSize(null);
    setCurrentPrice(item?.content ? null : item.price);
    setCurrentSku(item?.content ?
      {
        images: item.content[0]?.sku?.images
      } :
      {
        ...item,
        images: item.images
      }
    );
    // setCurrentSku(item?.content ? { images: item?.content[0]?.sku?.images} : item, { images: item?.content ? item?.content[0]?.sku?.images : item?.images });
  };


  const checkoutOrder = (isSplit) => {
    checkAuth(() => {
      const updatedSku = { ...currentSku, count: 1 };

      navigate('/confirm-order', { state: { sku: updatedSku, isSplit: isSplit } });
    }, 'Чтобы перейти к оформлению товара');
  };


  const imagesList = currentSku.length !== 0 ? currentSku.images || currentSku.sku?.images : product?.skus[0]?.images || [];

  const changeFavoriteStatus = (id) => {
    checkAuth(() => {
      if (isFavorite) {
        dispatch(deleteFavoriteItem(id)).then((meta) => {
          if (meta.meta.requestStatus === 'fulfilled') setIsFavorite(false);
        });
      } else {
        dispatch(addFavoriteItem(id)).then((meta) => {
          if (meta.meta.requestStatus === 'fulfilled') setIsFavorite(true);
        });
      }
    }, 'Чтобы добавить товар в избранное');
  };

  console.log(tabs, 'tabs');

  const getOnlySize = () => {
    if (tabs?.length === 0) {
      product?.skus.map((sku) => {
        if (sku.properties.length === 1 && sku.properties[0].type === 6) {
          sizeOnly.push({
            size: sku.properties[0].value,
            content: sku
          });
        }
      });
    }
  };

  getOnlySize();


  if (product && product.sizes.length === 0 && product.skus.length === 0 && product.properties.skus.length === 0) {
    return (
      <div className={b()}>
        <div className={b('not-found')}>
          Товара нет в наличии, приносим извинения за неудобства.
          <ButtonCustom
            type="primary"
            className={b('not-found-btn')}
            onClick={() => navigate(-1)}
          >
            Вернуться в каталог
          </ButtonCustom>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* <div className={b('breadcrumbs')}> */}
      {/*   <Link to="/" className={b('breadcrumbs-item')}>Главная</Link> */}
      {/*   <p className={b('b-delimiter')}>/</p> */}
      {/*   {breadCrumbItem && ( */}
      {/*     <> */}
      {/*       <p className={b('breadcrumbs-item')} onClick={() => navigate(-1)}>{breadCrumbItem}</p> */}
      {/*       <p className={b('b-delimiter')}>/</p> */}
      {/*     </> */}
      {/*   )} */}
      {/*   <p className={b('breadcrumbs-last')}>{product.name}</p> */}
      {/* </div> */}
      <div className={b()}>

        <div className={b('images')}>
          <div className={b('main-image-container')}>
            <Image src={selectedImage} alt="Product" className={b('main-image')} />
          </div>
          {imagesList && imagesList?.length > 1 && (
            <div className={b('thumbnail-carousel')}>
              <Carousel infinite={false} dots={false} slidesToShow={imagesList.length} slidesToScroll={1}>
                {imagesList && imagesList.map((image, index) => (
                  <div key={index} className={b('thumbnail')}>
                    <Image
                      src={image.link}
                      alt={`Thumbnail ${index + 1}`}
                      preview={false}
                      onClick={() => handleThumbnailClick(image.link)}
                      className={selectedImage === image.link ? b('thumbnail-image active') : b('thumbnail-image')}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          )}
        </div>
        <div className={b('content')}>
          <div className={b('top')}>
            <h1 className={b('name')}>{product.name}</h1>
            <ButtonCustom
              type="icon"
              icon={<Like />}
              onClick={() => changeFavoriteStatus(product.id)}
              className={isFavorite ? b('favorite-btn active') : b('favorite-btn')}
            />
          </div>
          <p className={b('price')}>
            {currentPrice ? `${formatPrice(currentPrice)} ₽` : 'выберите размер'}
          </p>
          <div className={b('sizes-grid')}>
            <div className={b('tab-header')}>

              {tabs.map((tab) => (
                <div
                  key={tab.key}
                  className={b(`tab ${activeTab === tab.key ? 'active' : ''}`)}
                  onClick={() => changeTabSize(tab)}
                >
                  {tab.title}
                </div>
              ))}

              {tabs?.length === 0 && sizeOnly.length === 0 && product?.skus.map((sku, index) => (
                <div
                  key={index}
                  className={b(`tab ${activeTab === sku.id ? 'active' : ''}`)}
                  onClick={() => changeTabSize(sku)}
                >
                  {`Вариант ${index + 1}`}
                </div>
              ))}

              {sizeOnly.length !== 0 && sizeOnly.map((size, index) => (
                <div
                  key={index}
                  className={b(`tab ${activeTab === size.content.id ? 'active' : ''}`)}
                  onClick={() => changeTabSize(size.content)}
                >
                  {size.size}
                </div>
              ))}
            </div>

            <div className={b('tab-content')}>
              {
                tabs.length === 0 && sizeOnly.length === 0 && currentSku.length !== 0 && (
                  <div className={b('test-prop')}>
                    {tabs.length === 0
                      && Object.entries(currentSku).length !== 0
                      && currentSku.properties && currentSku.properties.map((property, propIndex) => (
                      <p key={propIndex}>{property.type === 6 ? `Размер: ${property.value}` : property.value}</p>
                    ))}
                  </div>
                )
              }


              {tabs.map((tab) => (
                <div key={tab.key} className={b(`tab-pane ${activeTab === tab.key ? 'active' : ''}`)}>
                  {tab.content.map((item, index) => (
                    <div
                      key={index}
                      className={
                        b(`tab-item 
                        ${item.price <= 0 ? 'not-available' : ''} 
                        ${index === choosenSize ? 'choose' : ''}`)
                      }
                      onClick={() => {
                        if (item.price > 0) {
                          handleItemClickSize(index, item);
                        }
                      }}
                    >
                      <p className={b('tab-item-size')}>
                        {product?.category.includes('footwear') && tab.title}
                        {item.size === undefined && item.type} {item?.size}
                      </p>

                      <p
                        className={b('tab-item-price')}
                      >
                        {item.price > 0 ? `${formatPrice(item.price)} ₽` : 'Нет в наличии'}
                      </p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {sizeOnly && sizeOnly?.length !== 0 && <p className={b('clue')} onClick={() => navigate('/how-to-choose-size')}>Как выбрать размер?</p>}
          </div>
          <ButtonCustom
            type="primary"
            className={b('orderList-btn')}
            onClick={() => checkoutOrder(false)}
            disabled={!currentPrice}
          >
            Перейти к оформлению
          </ButtonCustom>

          {currentPrice && currentPrice < 40000 && (
            <div className={b('split')}>
              <div className={b('split-text')}>
                <p className={b('split-price')}>
                  {`${Math.ceil(currentPrice / 2)} ₽ × 2`}
                  <span className={b('split-sub')}> платежа в сплит на 2 месяца</span>
                </p>
                <div className={b('progress-bar')}>
                  <div />
                  <div />
                </div>
              </div>
              <ButtonCustom type="link" className={b('split-btn')} onClick={() => checkoutOrder(true)}>
                Оформить сплит
              </ButtonCustom>
            </div>
          )}

          {
            product.description && (
              <div className={b('info')}>
                <div className={b('tab-header-info')}>
                  {tabsInfo.map((tab) => (
                    <div
                      key={tab.key}
                      className={b(`tab-info ${activeTabInfo === tab.key ? 'active' : ''}`)}
                      onClick={() => setActiveTabInfo(tab.key)}
                    >
                      {tab.title}
                    </div>
                  ))}
                </div>
                <div className={b('tab-content-info')}>
                  {tabsInfo.map((tab) => (
                    <div key={tab.key} className={b(`tab-pane-info ${activeTabInfo === tab.key ? 'active' : ''}`)}>
                      {tab.content.description}
                    </div>
                  ))}
                </div>
              </div>
            )
          }

          <NeedHelp />

          <AuthModals
            isModalOpen={isModalOpen}
            isModalRegister={isModalRegister}
            isModalLogin={isModalLogin}
            warningTitle={warningTitle}
            handleClose={handleClose}
            handleRegister={handleRegister}
            handleLogin={handleLogin}
          />
        </div>
      </div>
      <div>
        {product?.category.length !== 0 && fromSearch === false && (
          <SpecialOffers
            category={product.category[product?.category.length >= 3 ? 2 : (product.category.length === 2 ? 1 : 0)]}
          />
        )}
      </div>
    </>
  );
}

export default ProductPage;
