import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
// import { router } from './config/router/routes';
// import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Main from './components/main';
import NotFound from './components/notFound';
import Layout from './components/layout';
import Profile from './components/profile';
import ProductPage from './components/productPage';
import Favorites from './components/favorites';
import Catalog from './components/catalog';
import ScrollToTop from './utils/scrollToTop';
import { RequireAuth } from './hoc/RequireAuth';
import OrderList from './components/orderList';
import PlaceOrder from './components/placeOrder';
import CatalogParent from './components/CatalogParent';
import GuaranteesAndOriginality from './components/infoPages/guaranteesAndOriginality/component';
import DeliveryAndPayment from './components/infoPages/deliveryAndPayment';
import CopyLink from './components/infoPages/copyLink';
import HowToChooseSize from './components/infoPages/howToChooseSize';
import Help from './components/infoPages/help';
import Contact from './components/infoPages/contact/component';
import BreadCrumbs from './ui-kit/breadCrumbs';
import './config/theme/core.scss'
import Reviews from './components/infoPages/reviews';
import OrderFullInfo from './components/order';
import ReferralUser from './hoc/ReferralUser';


function App() {

  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);
  const paths = pathnames.map((name, index) => {
    const path = `/${pathnames.slice(0, index + 1).join('/')}`;
    return { path, title: name.charAt(0).toUpperCase() + name.slice(1) };
  });

  return (
    <div>
      <ScrollToTop />
      {/* <BreadCrumbs paths={paths}/> */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Main />} />
          <Route path='/:id' element={
            <ReferralUser>
              <Main />
            </ReferralUser>
          } />
          <Route
            path="favorites"
            element={(
              <RequireAuth>
                <Favorites />
              </RequireAuth>
            )}
          />
          <Route
            path="orders"
            element={(
              <RequireAuth>
                <OrderList />
              </RequireAuth>
            )}
          />
          <Route
            path="profile"
            element={(
              <RequireAuth>
                <Profile />
              </RequireAuth>
            )}
          />
          <Route path="orders/:id" element={<OrderFullInfo />} />
          <Route path="products/:id" element={<ProductPage />} />
          <Route path="catalog" element={<CatalogParent />} />
          <Route path="confirm-order" element={<PlaceOrder />} />
          <Route path="guarantees-and-originality" element={<GuaranteesAndOriginality />} />
          <Route path="delivery-and-payment" element={<DeliveryAndPayment />} />
          <Route path="how-to-copy-link" element={<CopyLink />} />
          <Route path="how-to-choose-size" element={<HowToChooseSize />} />
          <Route path="help" element={<Help />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
