import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import bem from 'easy-bem';
import CustomTabs from '../../ui-kit/tabs';


import './style.scss';
import { getCatalogFiltered, getFavoriteItems } from '../../models/catalog/catalogSlice';
import { getToken } from '../../utils/token';
import Card from '../../ui-kit/card';
import axios from 'axios';
import { sort } from '../filters/data';
import InputCustom from '../../ui-kit/input';
import { Spin } from 'antd';


// TODO  по клику не сразу удаляется
function Favorites() {
  const b = bem('favorites');
  const dispatch = useDispatch();
  const token = getToken();
  const [favorites, setFavorites] = useState([]);
  const [nextPage, setNextPage] = useState('');
  const [sortOption, setSortOption] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingFav, setLoadingFav] = useState(false);

  const fetchFavorites = async () => {
    try {
      const result = await dispatch(getFavoriteItems('limit=6'));
      setFavorites(result.payload.results);
      setNextPage(result.payload.next);
      setLoadingFav(false);
    } catch (e) {
      setLoadingFav(false);
      console.log(e);
    }
  };

  useEffect(() => {
    setLoadingFav(true);
    fetchFavorites();
  }, []);

  const handleLoadMore = async () => {
    if (nextPage) {
      setLoading(true);
      try {
        const result = await axios.get(nextPage, {
          headers: {
            Authorization: `JWT ${token}`
          }
        });
        setLoading(false);
        setFavorites((prevData) => [...prevData, ...result.data.results]);
        setNextPage(result.data.next);
      } catch (e) {
        setLoading(false);
        console.error('Error loading more data:', e);
      }
    }
  };

  useEffect(() => {
    const fetchCatalogData = async () => {
      try {
        let query = 'limit=6&is_favorited=true';

        if (sortOption) {
          query += `&ordering=${sortOption}`;
        }

        const result = await dispatch(getCatalogFiltered(query));
        if (result.payload) {
          setFavorites(result.payload.results);
          setNextPage(result.payload.next);
        } else {
          console.error('Unexpected response structure:', result);
        }
      } catch (e) {
        console.error('Error fetching catalog:', e);
      }
    };

    fetchCatalogData();
  }, [sortOption]);

  const handleSortChange = (value) => {
    setSortOption(value);
  };

  return (
    <div className={b()}>
      <div className={b('menu')}>
        <CustomTabs />
      </div>
      <div className={b('content')}>
        <div className={b('top')}>
          <h2 className={b('title')}>Избранное</h2>
          <InputCustom
            label='Сортировка:'
            name="sort"
            options={sort}
            value={sortOption}
            placeholder='сортировка по...'
            onChange={handleSortChange}
            type="select"
            className={b('sort-select')}
          />
        </div>
        <div className={b('list', {'loading-box': loadingFav})}>
          {favorites.length > 0 ? favorites.map((fav) => <Card categoryOrBrand='Избранное' item={fav}/>) :
            <div className={b('loading-box')}>
              {loadingFav ? <Spin/> : <h1 className={b('none')}>Нет избранных товаров</h1>}
            </div>}
        </div>
        {nextPage && (
          <button onClick={handleLoadMore} className={b('see-more')}>{loading ? <Spin/> : 'Показать еще'}</button>
        )}
      </div>
    </div>
  );
}

export default Favorites;
