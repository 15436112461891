export const brandsArr = [
  { value: "Jordan", count: 205 },
  { value: "Nike", count: 457 },
  { value: "Asics", count: 61 },
  { value: "adidas originals", count: 134 },
  { value: "Balenciaga", count: 1 },
  { value: "Converse", count: 26 },
  { value: "Champion", count: 1 },
  { value: "PUMA", count: 20 },
  { value: "Crocs", count: 1 },
  { value: "DC Shoes", count: 3 },
  { value: "Dickies", count: 1 },
  { value: "Dr.Martens/马汀博士", count: 2 },
  { value: "FILA", count: 1 },
  { value: "GUCCI/古驰", count: 1 },
  { value: "HERMES/爱马仕", count: 1 },
  { value: "New Balance", count: 174 },
  { value: "Jifffly", count: 1 },
  { value: "Kappa", count: 2 },
  { value: "李宁", count: 6 },
  { value: "MIHARA YASUHIRO/三原康裕", count: 1 },
  { value: "MISTERY", count: 1 },
  { value: "Mizuno", count: 3 },
  { value: "NOCOPY", count: 1 },
  { value: "OLD ORDER", count: 1 },
  { value: "Onitsuka Tiger", count: 3 },
  { value: "Q-AND", count: 1 },
  { value: "Reebok", count: 11 },
  { value: "SALOMON/萨洛蒙", count: 2 },
  { value: "Skechers", count: 1 },
  { value: "Under Armour", count: 7 },
  { value: "Vans", count: 22 },
  { value: "vibram", count: 1 },
  { value: "adidas", count: 21 },
  { value: "adidas neo", count: 2 },
  { value: "顽刻ollieskate", count: 7 },
  { value: "xVESSEL", count: 1 },
  { value: "youthloser", count: 1 }
];

export const sort = [
  {
    value: 'price',
    label: 'Подешевле',
  },
  {
    value: '-price',
    label: 'Подороже',
  },
  {
    value: '-score',
    label: 'Популярные',
  },
];

export const UKSize = [
  3,
  4,
  5,
  5.5,
  6,
  6.5,
  7,
  7.5,
  8,
  8.5,
  9,
  9.5,
  10,
  10.5,
  11,
  11.5,
  12,
  12.5,
  13,
  13.5,
  14,
  14.5,
  15,
  15.5,
  16,
  17
];

export const USSize = [
  4.5,
  5.5,
  6,
  6.5,
  7,
  7.5,
  8,
  8.5,
  9,
  9.5,
  10,
  10.5,
  11,
  11.5,
  12,
  12.5,
  13,
  13.5,
  14,
  14.5,
  15,
  15.5,
  16,
  16.5,
  17,
  18
];

export const EUSize = [
  36,
  36.5,
  37,
  37.5,
  38,
  38.5,
  39,
  40,
  40.5,
  41,
  41.5,
  42,
  42.5,
  43,
  44,
  44.5,
  45,
  45.5,
  46,
  47,
  47.5,
  48,
  48.5,
  49,
  49.5,
  50,
  50.5,
  51,
  51.5,
  52.5
];

export const RUSSize = [
  34,
  34.5,
  35,
  35.5,
  36,
  36.5,
  37,
  37.5,
  38,
  38.5,
  39,
  39.5,
  40,
  40.5,
  41,
  41.5,
  42,
  42.5,
  43,
  43.5,
  44,
  44.5,
  45,
  45.5,
  46,
  46.5,
  47,
  47.5,
  48,
  48.5,
  49
];

export const apparelSize = [
  "XXS",
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "XXL",
  "XXXL",
]
//
// 26 'US'
// component.js:43 26 'US'
// component.js:44 30 'EU'
// component.js:45 38 'RU'
// component.js:46 26 'UKK'
// TODO подумать над этим
// const brands = useMemo(() => {
//   const fetchAllBrands = async () => {
//     try {
//       return await preloadBrands(categoryType);
//     } catch (e) {
//       console.error('Error fetching brands:', e);
//       return [];
//     }
//   };
//
//   return fetchAllBrands();
// }, [categoryType]);
