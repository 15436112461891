import React from 'react';
import bem from 'easy-bem';
import Carousel from '../carousel';

import './style.scss';
import SearchByLink from '../searchByLink';
import SpecialOffers from '../specialOffers';
import ScrollToTopButton from '../../ui-kit/scrollToTopButton';


function Main() {
  const b = bem('main');


  return (
    <div>
      <section>
        <Carousel />
      </section>

      <SearchByLink className={b('top-search')} />

      <SpecialOffers category="footwear" />

      <SpecialOffers category="apparel" />


      <div className={b('bottom-category')}>
        <SpecialOffers category="accessories" />
      </div>

      <ScrollToTopButton/>

      <SearchByLink light className={b('bottom-search')} />
    </div>
  );
}

export default Main;
