import React from 'react';
import bem from 'easy-bem';
import { useDispatch } from 'react-redux';
import ButtonCustom from '../button';
import { ReactComponent as Like } from '../../assets/icons/heart-black.svg';
import { ReactComponent as Delete } from '../../assets/icons/x.svg';

import './style.scss';
import { formatPrice } from '../../utils/formatPrice';


function BasketItem({
  item, onSelect, selected, handleDelete, handleIncrement, handleDecrement, tryy, size
}) {
  const b = bem('basket-item');

  return (
    <div className={b()}>
      {/* <ButtonCustom */}
      {/*   type="checkbox" */}
      {/*   className={b('checkbox')} */}
      {/*   checked={selected} */}
      {/*   onChange={() => onSelect(item.item_sku.id)} */}
      {/* /> */}
      <div className={b('img-container')}>
        <img src={item.sku ? item?.sku.images[0].link : item.images[0].link} alt="img" className={b('img')} />
      </div>

      <div className={b('content')}>
        <div className={b('info')}>
          <div className={b('name-size')}>
            <h3 className={b('name')}>{item.sku ? item?.sku.name : item.name}</h3>
            <p className={b('size')}>
              <span className={b('sub-size')}>{item.size || item.properties[0].type === 6 ? 'Размер:' : 'Тип:'}</span>
              {' '}
              {item.sku ? (item.size || item.type) : (item.properties[0].type === 6 ? item.properties[0].value : 'безразмерное')}
              {/* {size ? size : (firstSizeKey ? `${firstSizeValue} ${firstSizeKey}` : 'Нет размера')} */}


            </p>
          </div>
          <p className={b('price')}>
            {item.count > 1
              ? formatPrice(item.price * item.count)
              : formatPrice(item.price)}
            {' '}
            ₽
          </p>
        </div>

        <div className={b('btns')}>
          {/* <div className={b('right')}> */}
          {/*   <ButtonCustom type="icon" icon={<Like />} onClick={() => console.log('work')} className={b('like')} /> */}
          {/*   /!* <ButtonCustom type="icon" icon={<Delete />} onClick={() => handleDelete(item.item_sku.id)} className={b('delete')} /> *!/ */}
          {/* </div> */}

          <div className={b('count')}>
            <p className={`${b('count-decrement')} ${item.count === 1 ? b('count-decrement-disabled') : ''}`} onClick={() => handleDecrement(item)} />
            <p className={b('count-item')}>{tryy || item.count}</p>
            <p className={b('count-increment')} onClick={() => handleIncrement(item)}>+</p>
          </div>

        </div>
      </div>
    </div>
  );
}
//TODO изменила   {item.size || ''} на {item.sku ? item.size : 'безразмерное'}
export default BasketItem;
