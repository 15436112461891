import { useParams, useNavigate } from 'react-router-dom';
import Registration from '../components/registration';
import { useState, useEffect } from 'react';

function ReferralUser({ children }) {
  const { id } = useParams(); // Получаем параметр из URL
  const [isOpen, setIsOpen] = useState(false); // Изначально модалка закрыта
  const navigate = useNavigate(); // Для навигации
  const targetRoute = '/profile'; // Путь для навигации на главную страницу

  // Функция для закрытия модалки
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (id) {
      // Если есть реферальный ID, открываем модалку
      setIsOpen(true);
      // Перенаправляем на главную страницу
      // navigate(targetRoute);
    }
  }, [id, navigate, targetRoute]);

  return (
    <>
      {children}
      <Registration
        isOpen={isOpen}
        onClose={handleClose}
        navigate={() => navigate(targetRoute)}
        targetRoute={targetRoute}
        referralId={id}
      />
    </>
  );
}

export default ReferralUser;
