import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import bem from 'easy-bem';
import { ReactComponent as Like } from '../../assets/icons/heart-like.svg';
import ButtonCustom from '../button';

import './style.scss';
import { addFavoriteItem, deleteFavoriteItem, getProductById } from '../../models/catalog/catalogSlice';
import { formatPrice } from '../../utils/formatPrice';
import useAuthCheck from '../../utils/useAuthCheck';
import AuthModals from '../../components/authModals';


function Card({ item, categoryOrBrand }) {
  const b = bem('card');
  const dispatch = useDispatch();
  const [isFavorite, setIsFavorite] = useState(item.is_favorited);
  const navigate = useNavigate();

  const handleItemClick = (id) => {
    const url = `/products/${id}`;
    window.open(url, '_blank');
  };
  // const handleItemClick = (id) => {
  //   const url = `/products/${id}`;
  //   const params = new URLSearchParams();
  //   params.append('categoryOrBrand', categoryOrBrand); // Добавляем categoryOrBrand в параметры URL
  //   window.open(url + '?' + params.toString(), '_blank');
  // };

  const {
    isModalOpen,
    isModalRegister,
    isModalLogin,
    warningTitle,
    checkAuth,
    handleRegister,
    handleLogin,
    handleClose,
    // test
  } = useAuthCheck();

  const changeFavoriteStatus = (id, event) => {
    event.stopPropagation();
    checkAuth(() => {
      if (isFavorite) {
        dispatch(deleteFavoriteItem(id)).then((meta) => {
          if (meta.meta.requestStatus === "fulfilled") setIsFavorite(false);
        });
      } else {
        dispatch(addFavoriteItem(id)).then((meta) => {
          if (meta.meta.requestStatus === "fulfilled") setIsFavorite(true);
        });
      }
    }, 'Чтобы добавить товар в избранное');
  };

  return (
    <>
      <div className={b()} onClick={() => handleItemClick(item.id)}>
        <div className={b('top')}>
          <div onClick={(event) => changeFavoriteStatus(item.id, event)}>
            <Like className={isFavorite ? b('like active') : b('like')}/>
          </div>
          <img src={item.image} alt="img" className={b('img')}/>
        </div>
        <div className={b('bottom')}>
          <h3 className={b('name')}>{item.name}</h3>
          <p className={b('price')}>
            {formatPrice(item.price)}
            {' '}
            ₽
          </p>
        </div>
      </div>
      <AuthModals
        isModalOpen={isModalOpen}
        isModalRegister={isModalRegister}
        isModalLogin={isModalLogin}
        warningTitle={warningTitle}
        handleClose={handleClose}
        handleRegister={handleRegister}
        handleLogin={handleLogin}
        // test={test}
      />
    </>
  );
}

export default Card;
