import React, { useEffect, useState, useCallback } from 'react';
import bem from 'easy-bem';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { getCatalogFiltered, getPopularItems } from '../../models/catalog/catalogSlice';
import { ReactComponent as SearchIcon } from '../../assets/icons/search-black.svg';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import { ReactComponent as Clean } from '../../assets/icons/x.svg';
import InputCustom from '../../ui-kit/input';
import ButtonCustom from '../../ui-kit/button';
import Card from '../../ui-kit/card';

import './style.scss';


const settings = {
  infinite: false,
  speed: 400,
  slidesToShow: 3,
  slidesToScroll: 1,
  prevArrow: <Arrow />,
  nextArrow: <Arrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function SearchHeader({ onClose }) {
  const b = bem('search-header');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [result, setResult] = useState([]);
  const [popularItems, setPopularItems] = useState([]);
  const [noResult, setNoResult] = useState('Начните вводить название товара!');

  const fetchData = async (query) => {
    if (query) {
      try {
        const response = await dispatch(getCatalogFiltered(`limit=8&search=${query}`));

        if (response.payload.count === 0) {
          setNoResult('К сожалению, ничего не нашлось');
        }

        setResult(response.payload.results);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    } else {
      setResult([]);
    }
  };

  const debouncedFetchData = useCallback(debounce(fetchData, 500), []);

  useEffect(() => {
    debouncedFetchData(search);


    return () => {
      debouncedFetchData.cancel();
    };
  }, [search, debouncedFetchData]);


  useEffect(() => {
    const fetchPopularItems = async () => {
      const response = await dispatch(getPopularItems());

      setPopularItems(response.payload);
    };

    fetchPopularItems();
  }, []);


  const handleSearch = (value) => {
    setSearch(value);
  };

  const goToCatalog = () => {
    onClose();
    navigate('/catalog', { state: { search: search } });
  };

  return (
    <div className={b()}>
      <div className={b('visible')}>
        <InputCustom
          prefix={<SearchIcon className={search ? b('search-icon black') : b('search-icon gray')} />}
          suffix={search && <Clean className={b('icon-clean')} onClick={() => setSearch('')} />}
          name="link"
          type="form"
          placeholder="Введите запрос..."
          className={b('input')}
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />


        <div className={b('content')}>
          <div className={b('popular-items')}>
            <h3 className={b('p-title')}>Часто ищут</h3>
            {popularItems.map((item, i) => (
              <div key={i} className={b('p-item')} onClick={() => setSearch(item.text)}>
                <SearchIcon className={b('popular-icon')} />
                {' '}
                {item.text}
              </div>
            ))}
          </div>


          <div className={b('result-mobile')}>
            {result.length > 0 ? (
              <>
                <ButtonCustom type="text" className={b('link-btn')} onClick={goToCatalog}>
                  Смотреть
                  все
                </ButtonCustom>
                <div className={b('result-box')}>
                  {result.map((item) => (
                    <div onClick={() => onClose()}>
                      <Card item={item} />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <h3 className={b('no-result')}>{noResult}</h3>
            )}
          </div>
          <div className={b('result')}>
            {
              result.length > 0 ? (
                <>
                  <div className={b('buttons')}>
                    <ButtonCustom type="text" className={b('link-btn')} onClick={goToCatalog}>
                      Смотреть
                      все
                    </ButtonCustom>
                  </div>
                  <Slider
                    {...settings}
                    className={b('slider')}
                  >
                    {result.map((item, i) => (
                      <div onClick={() => onClose()}>
                        <Card key={i} item={item} />
                      </div>
                    ))}
                  </Slider>
                </>

              ) : (
                <h3 className={b('no-result')}>{noResult}</h3>
              )
            }

          </div>

        </div>
      </div>

    </div>
  );
}

export default SearchHeader;
