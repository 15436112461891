import React from 'react';
import { useNavigate } from 'react-router-dom';
import bem from 'easy-bem';
import { ReactComponent as Arrow } from '../../../assets/icons/arrow.svg';
import '../style.scss';


const DeliveryAndPayment = () => {
  const b = bem('info');
  const navigate = useNavigate();

  return (
    <div className={b()}>
      <div className={b('top')}>
        <h2 className={b('title')}>Доставка и оплата</h2>
      </div>
      <div className={b('content')}>
        <h3 className={b('subtitle')}>Сколько занимает доставка из PoizonRF</h3>
        <p className={b('text')}>
          Среднее время доставки товара от момента покупки до его получения в нашем магазине в Москве:
          <div className={b('br')}>
            <br/>
          </div>
          7-10 дней. - экспресс доставка самолетом
          <div className={b('br')}>
            <br/>
          </div>
          10-20 дней. - обычная доставка наземным транспортом
          <div className={b('br')}>
            <br/>
          </div>
          Вы можете забрать свой заказ в Москве или мы доставим курьером или в пункт самовывоза СДЭК в любом городе
          России.
        </p>
        <div className={b('color-block')}/>
        <h3 className={b('subtitle second')}>Как работает доставка PoizonRF</h3>
        <p className={b('text')}>
          После оформления заказа и оплаты вы сможете выбрать способ доставки и указать данные получателя.
          Для этого воспользуйтесь нашим приложением Unicorn, которое работает прямо в Телеграмме.
          <div className={b('br')}>
            <br/>
          </div>
          Выбор и оформление доставки выглядит так:
        </p>
        <p className={b('text')}>
          На выбор вам будет предоставлено 3 способа доставки
          <div className={b('br')}>
            <br/>
          </div>
          <li className={b('li')}>
            Самовывоз СДЭК из ПВЗ. Вы сможете оформить доставку до ближайшего в вашем городе пункта выдачи СДЭК.
            В приложении вы сможете заполнить данные получателя и выбрать из списка ваш Город и Пункт выдачи.
          </li>
          <li className={b('li')}>
            Доставка курьером СДЭК. Вы можете оформить доставку до вашей квартиры курьером.
            В приложении вы также заполняете свои данные, выбираете город и указываете полный адрес доставки.
          </li>
          <li className={b('li')}>
            Забрать из магазина в Москве. Если вы живете в Москве и хотите забрать заказ самовывозом,
            то сделать это вы можете приехав к нам в магазин, который находится по адресу проспект Мира 188б корпус 3
            (метро ВДНХ).
            В приложении вы также можете заполнить данные получателя и указать комментарий, если хотите сообщить нам
            какую-то информацию.
          </li>
        </p>
        <div className={b('color-block')}/>
        <h3 className={b('subtitle second')}>Отслежевание заказа</h3>
        <p className={b('text')}>
          После того как вы совершили покупку и заполнили данные доставки,
          вы сможете отслеживать статусы вашего заказа в приложении,
          а также вам будут приходить уведомления после каждого изменения статуса.
        </p>
        <h3 className={b('subtitle second')}>Сроки доставки и что происходит с заказом после оплаты</h3>
        <p className={b('text')}>
          Заказ передается на закупку. Выкуп заказа занимает порядка 1-3 часов в рабочее время.
          <div className={b('br')}>
            <br/>
          </div>
          После выкупа мы ожидаем товар на нашем складе в Китае.
          Обычно время доставки до склада занимает 2-3 дня, но возможно, что товар приедет и за 1 день
          или может доставляться до 6 дней.
          <div className={b('br')}>
            <br/>
          </div>
          После получения товара на складе мы отправляем его в Россию на следующий день.
          Срок доставки до нашего магазина в Москве составляет порядке 2-3 недель
          (максимальный срок до 21 рабочего дня)
          <div className={b('br')}>
            <br/>
          </div>
          После получения товара в магазине у вас появится статус "Принято в магазине в Москве"
          - это означает, что если вы оформляли самовывоз из магазина, то можно приходить и забирать ваш товар.
          Если вы оформляли заказ в другой город России, то после сортировки груза, все товары на следующий день будут
          отправлены в СДЭК и у вас появится новый статус "Доставляется СДЭК" + высветится трек номер по которому вы
          сможете
          отслеживать дальнейшую доставку до вашего адреса.
          <div className={b('br')}>
            <br/>
          </div>
          После того как вы получили товар. ваш статус изменится
          на "Завершен" и в приложении вам начислятся баллы для следующих покупок.
        </p>
        <p className={b('back')} onClick={() => navigate(-1)}><Arrow className={b('arrow')}/> Назад</p>
      </div>

    </div>
  );
};

export default DeliveryAndPayment;
