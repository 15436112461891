import React from 'react';
import bem from 'easy-bem';
import { Button, Checkbox } from 'antd';

import './style.scss';


function ButtonCustom(props) {
  const b = bem('button-v2');
  const {
    className: classNameProp,
    type = 'default',
    imgUrl,
    children,
    targetRoute,
    onClick,
    ...rest
  } = props;

  const handleClick = () => {
    onClick(targetRoute);
  };

  const cnbox = classNameProp
    ? `${classNameProp} ${b({ checkbox: true })}`
    : `${b({ checkbox: true })}`;

  if (type === 'checkbox') {
    return (
      <Checkbox
        className={cnbox}
        {...rest}
      >
        {children}
      </Checkbox>
    );
  }


  const cn = classNameProp
    ? `${classNameProp} ${b()}`
    : `${b()}`;

  if (type === 'link') {
    return (
      <Button onClick={onClick} className={cn} type={type} {...rest}>
        {children}
      </Button>
    );
  }

  if (type === 'icon') {
    return (
      <Button className={cn} {...rest} onClick={handleClick}>
        {children}
      </Button>
    );
  }

  return (
    <Button className={cn} type={type} {...rest} onClick={onClick}>
      {children}
    </Button>
  );
}

export default ButtonCustom;
export { ButtonCustom as Button };
