export const categoryNames = {
  slippers: 'Тапочки',
  casual: 'Повседневные',
  sport: 'Спортивные',

  featured_tops: 'Верхняя одежда',
  featured_jacket: 'Жакеты',
  pants: 'Штаны',
  workout: 'Спортивное',

  bags: 'Сумки',
  glasses: 'Очки',
  scarf: 'Шарфы',
  bags_accessories: 'Аксессуары для сумок',
  jewelry: 'Украшения',
  belt: 'Ремни',
  watch: 'Часы',
  key_chain: 'Брелки',
  hat: 'Головные уборы',
  brooch: 'Броши',
  tie: 'Галстуки',
  hair_accessories: 'Аксессуары для волос',
  face_mask: 'Маски',
  gold: 'Золото',
  platinum: 'Платина',
  diamond: 'Бриллианты',

  footwear: 'Обувь',
  apparel: 'Одежда',
  accessories: 'Аксессуары',


  passport_sentence: 'Обложки для паспорта',
  card_holder: 'Держатели карт',
  mobile_phone_bag: 'Чехлы для телефонов',
  storage_box: 'Контейнеры для хранения',
  children_bag: 'Детские сумки',
  chest_bag: 'Нагрудные сумки',
  fanny_pack: 'Поясные сумки',
  gym_bag: 'Сумки для спорта',
  diagonal_bag: 'Сумки через плечо',
  tote: 'Тотэ',
  wallet: 'Кошельки',
  underarm_bag: 'Сумки подмышку',
  backpack: 'Рюкзаки',
  children_headband: 'Детские повязки на голову',
  key_case: 'Ключницы',
  brief_case: 'Портфели',
  luggage_tag: 'Бирки для багажа',
  anklet: 'Браслеты на лодыжку',
  wristband: 'Браслеты',
  cufflinks: 'Запонки',
  makeup_bag: 'Сумки для косметики',
  quartz: 'Кварцевые часы',
  mechanical: 'Механические часы',
  smart: 'Умные часы',
  alarm_clock: 'Будильники',
  peaked_cap: 'Кепки',
  berets: 'Береты',
  fisherman: 'Шляпы для рыбалки',
  other: 'Прочее',
  headband: 'Повязки на голову',
  hairpin: 'Заколки для волос',
  earnings: 'Серьги',
  bars: 'Барсы',
  headphone_bag: 'Сумки для наушников',
  necklace_bag: 'Сумки для украшений',
  wash_bag: 'Сумки для стирки',
  coin_purse: 'Монетницы',
  sunglasses: 'Солнцезащитные очки',
  optical_frame: 'Оптические оправы',
  fleece: 'Флис',
  silk: 'Шелк',
  tape: 'Ленты',
  bag_peripheral: 'Аксессуары для сумок',
  necklace: 'Ожерелья',
  pendant: 'Подвески',
  bracelet: 'Браслеты',
  earning: 'Серьги',
  ring: 'Кольца',
  bracelet2: 'Браслеты',
  jewelry_accessories: 'Ювелирные аксессуары',

  t_shirt: 'Футболки',
  hoodie: 'Толстовки',
  polo_shirt: 'Поло',
  sweater: 'Свитеры',
  shirt: 'Рубашки',
  vest: 'Жилеты',
  cotton_clothes: 'Хлопковые вещи',
  jacket: 'Куртки',
  down_jacket: 'Пуховики',
  baseball_uniform: 'Форма для бейсбола',
  coat: 'Пальто',
  jackets: 'Куртки',
  windbreaker: 'Ветровки',
  leather_jacket: 'Кожаная куртка',
  denim_jacket: 'Джинсовая куртка',
  suit: 'Костюмы',
  overralls2: 'Комбинезоны',
  jeans: 'Джинсы',
  casual_shorts: 'Повседневные шорты',
  underwear: 'Белье',
  denim_short: 'Джинсовые шорты',
  basketball_pants: 'Баскетбольные штаны',
  workout_clothes: 'Одежда для тренировки',
  basketball_vest: 'Жилеты для баскетбола',
  workout_pants: 'Штаны для тренировки',
  sun_protection: 'Защита от солнца',
  overalls: 'Комбинезоны',
  ski_pants: 'Лыжные штаны',
  ski_suit: 'Лыжные костюмы',

  running: 'Беговые',
  training: 'Тренировочные',
  basketball: 'Баскетбольные',
  golf: 'Для гольфа',
  soccer: 'Футбольные',
  tennis: 'Теннисные',
  badminton: 'Кроссовки для бадминтона',
  sneakers: 'Кроссовки',
  vintage_basketball: 'Винтажные баскетбольные',
  daddy: 'Дадди',
  canvas: 'Канвасные',
  bautou: 'Баутоу',
  sport_sandals: 'Спортивные сандалии',
  cycling: 'Велосипедные',
  boots: 'Сапоги',
  snow: 'Снегоступы',
  chelsea: 'Челси',
  martin: 'Мартинсы',
  outdoor: 'Для улицы',
  short: 'Шорты',
  flip_flops: 'Шлепанцы',
};
