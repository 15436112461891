export const categoriesArray = [
  {
    title: 'footwear',
    categories: [
      {
        title: 'sport',
        subcategories: [
          'running',
          'training',
          'basketball',
          'golf',
          'soccer',
          'tennis',
          'badminton'
        ]
      },
      {
        title: 'casual',
        subcategories: [
          'sneakers',
          'vintage_basketball',
          'daddy',
          'canvas'
        ]
      },
      {
        title: 'slippers',
        subcategories: [
          'bautou',
          'sport_sandals'
        ]
      }
      // subcategories
    ]
  },
  {
    title: 'apparel',
    categories: [
      {
        title: 'featured_tops',
        subcategories: [
          't_shirt',
          'hoodie',
          'polo_shirt',
          'sweater',
          'shirt',
          'vest'
        ]
      },
      {
        title: 'featured_jacket',
        subcategories: [
          'cotton_clothes',
          'jacket',
          'down_jacket',
          'baseball_uniform',
          'coat',
          'jackets',
          'windbreaker',
          'leather_jacket',
          'denim_jacket',
          'suit'
        ]
      },
      {
        title: 'pants',
        subcategories: [
          'overralls2',
          'jeans',
          'casual_shorts',
          'underwear',
          'denim_short'
        ]
      },
      {
        title: 'workout',
        subcategories: [
          'basketball_pants',
          'workout_clothes',
          'basketball_vest',
          'workout_pants'
        ]
      },
      // subcategories
    ]
  },
  {
    title: 'accessories',
    categories: [
      {
        title: 'bags',
        subcategories: [
          'backpack',
          'underarm_bag',
          'wallet',
          'tote',
          'diagonal_bag',
          'gym_bag',
          'fanny_pack',
          'chest_bag',
          'children_bag',
          'storage_box',
          'mobile_phone_bag',
          'card_holder',
          'passport_sentence',
          'headphone_bag',
          'necklace_bag',
          'wash_bag',
          'coin_purse'
        ]
      },
      {
        title: 'glasses',
        subcategories: [
          'sunglasses',
          'optical_frame',
        ]
      },
      {
        title: 'scarf',
        subcategories: [
          'fleece',
          'silk',
        ]
      },
      {
        title: 'bags_accessories',
        subcategories: [
          'tape',
          'bag_peripheral',
        ]
      },
      {
        title: 'jewelry',
        subcategories: [
          'necklace',
          'pendant',
          'bracelet',
          'earning',
          'ring',
          'bracelet2',
          'jewelry_accessories'
        ]
      },
      {
        title: 'watch',
        subcategories: [
          'quartz',
          'mechanical',
          'smart',
          'alarm_clock'
        ]
      },
      {
        title: 'key_chain',
        subcategories: []
      },
      {
        title: 'hat',
        subcategories: [
          'peaked_cap',
          'berets',
          'fisherman',
          'other'
        ]
      },
      {
        title: 'hair_accessories',
        subcategories: [
          'headband',
          'hairpin',
        ]
      },
      {
        title: 'gold',
        subcategories: [
          'earnings',
          'bars',
        ]
      },
      {
        title: 'platinum',
        subcategories: []
      },
      {
        title: 'face_mask',
        subcategories: []
      },
      {
        title: 'brooch',
        subcategories: []
      },
      {
        title: 'diamond',
        subcategories: []
      },
      {
        title: 'tie',
        subcategories: []
      },
      {
        title: 'belt',
        subcategories: []
      },
      // subcategories
    ]
  },

];
