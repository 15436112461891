import { useLocation, Navigate } from 'react-router-dom';
import { getToken } from '../utils/token';


function RequireAuth({ children }) {
  const location = useLocation();
  const token = getToken();

  if (!token) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
}

export { RequireAuth };
