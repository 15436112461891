import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { message, Form, Upload, Select } from 'antd';
const BASE_URL = process.env.REACT_APP_BACKEND_HOST;
import ImgCrop from 'antd-img-crop';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import bem from 'easy-bem';
import CustomTabs from '../../ui-kit/tabs';
import './style.scss';
import { fetchGetMe, fetchRefillUser } from '../../models/user/userSlice';
import InputCustom from '../../ui-kit/input';
import ButtonCustom from '../../ui-kit/button';
import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import { rulesValidationEmail, validateFullName } from '../../utils/validation';
import { PlusOutlined } from '@ant-design/icons';
import { getOrdersList } from '../../models/order/orderSlice';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import axios from 'axios';
import debounce from 'lodash.debounce';

function Profile() {
  const b = bem('profile');
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [addressValue, setAddressValue] = useState('');
  const [addressWarning, setAddressWarning] = useState(false);
  const [userData, setUserData] = useState(null);
  const [orders, setOrders] = useState([]);
  const [cityCode, setCityCode] = useState(0);
  const [fileList, setFileList] = useState([]);

  const [filteredOptions, setFilteredOptions] = useState([]);
  const [optionsCity, setOptionsCity] = useState([]);
  const [cityId, setCityId] = useState(localStorage.getItem('city_id') || 0);
  const { Option } = Select;

  const handleSearch = debounce(async (value) => {
    if (value) {
      try {
        const response = await axios.get(`${BASE_URL}/delivery/pvz/?limit=10&name=${value}&city=${cityId}`);
        setFilteredOptions(response.data.results);
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    } else {
      setFilteredOptions([]);
    }
  }, 400);

  const handleSearchCity = debounce(async (value) => {
    if (value) {
      try {
        const response = await axios.get(`${BASE_URL}/delivery/cities/?name=${value}`);

        const cities = response.data;

        const nameCounts = cities.reduce((acc, city) => {
          acc[city.name] = (acc[city.name] || 0) + 1;

          return acc;
        }, {});

        const processedCity = cities.map(city => ({
          ...city,
          showRegion: nameCounts[city.name] > 1
        }));


        setOptionsCity(processedCity);
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    } else {
      setOptionsCity([]);
    }
  }, 400);
  const handleCityChange = (value) => {
    const selectedCity = optionsCity.find(city => city.id === value); // Поиск города по id
    if (selectedCity) {
      setCityCode(selectedCity.code);
      setCityId(selectedCity.id);
      form.setFieldsValue({
        city: `${selectedCity.name}${selectedCity.showRegion ? `, ${selectedCity.region.name}` : ''}`
      });
      form.setFieldsValue({ pvz_cdek: '' });

      if (!addressValue.includes(selectedCity.name)) {
        setAddressWarning(true);
      }
    }
    console.log('Selected City ID:', cityId);
  };

  const getAllOrders = async () => {
    try {
      const response = await dispatch(getOrdersList());
      setOrders(response.payload);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAllOrders();
    const fetchUserData = async () => {
      try {
        const data = await dispatch(fetchGetMe());
        setUserData(data.payload);
      } catch (error) {
        console.error('Ошибка при получении данных пользователя:', error);
      }
    };

    fetchUserData();
  }, [dispatch]);

  useEffect(() => {
    if (userData) {
      const fieldsToSet = {};
      if (userData.full_name) fieldsToSet.full_name = userData.full_name;
      if (userData.email) fieldsToSet.email = userData.email;
      if (userData.phone) fieldsToSet.phone = formatPhoneNumber(userData.phone);
      if (userData.pvz_cdek) fieldsToSet.pvz_cdek = userData.pvz_cdek.address;
      if (userData.delivery_address) {
        setAddressValue(userData.delivery_address);
        fieldsToSet.delivery_address = userData.delivery_address;
      }
      if (userData.city) fieldsToSet.city = userData.city;
      if (userData.city_code_cdek) setCityCode(userData.city_code_cdek);

      if (userData.photo) {
        setFileList([
          {
            uid: `${Date.now()}`, // Используем текущее время для уникальности
            name: 'photo',
            status: 'done',
            url: userData.photo,
          },
        ]);
      }
      form.setFieldsValue(fieldsToSet);
    }
  }, [userData, form]);

  const handleSubmitPatch = async () => {

    if (cityId !== 0) {
      localStorage.setItem('city_id', cityId);
    }
    const userInfo = await form.validateFields();
    const changedUserData = {};
    console.log(userInfo);
    if (userData){
      if (userData.delivery_address !== addressValue ){
        changedUserData['address'] = addressValue;
      } if (userData.city !== userInfo['city']){
        changedUserData['city'] = userInfo['city']
      } if (userData.pvz_cdek?.address !== userInfo['pvz_cdek'] || !userData.pvz_cdek?.address){
        changedUserData['pvz_cdek'] = userInfo['pvz_cdek'];
        // console.log(delivery.pvz);
        // console.log(initialUserData.pvz_cdek.id);
      } if (userData.full_name !== userInfo['full_name']){
        changedUserData['full_name'] = userInfo['full_name']
      } if (userData.email !== userInfo['email']){
        changedUserData['email'] = userInfo['email']
      } if (userData.city_code_cdek !== cityCode) {
        changedUserData['city_code_cdek'] = cityCode;
      }

      if (fileList.length > 0) {
        const newPhoto = fileList[fileList.length - 1].url; // Получаем URL последнего фото

        if (userData.photo !== newPhoto) {
          changedUserData.photo = newPhoto; // Добавляем фото в измененные данные
        }
      }
    }
    console.log(changedUserData);
    if (Object.keys(changedUserData).length > 0) {
      dispatch(fetchRefillUser(changedUserData));
    }

  };


  const copyToClipboardFallback = (textToCopy) => {
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;

    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const message = successful ? 'Текст скопирован!' : 'Не удалось скопировать текст';
      console.log('Fallback: Copying text command was ' + (successful ? 'successful' : 'unsuccessful'));
      if (successful) {
        message.success('Текст скопирован!');
      } else {
        message.error('Не удалось скопировать текст');
      }
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      // message.error('Не удалось скопировать текст'); // пока что уберем
    }

    // Удаляем временное текстовое поле
    document.body.removeChild(textArea);
  };

  const copyToClipboard = (textToCopy) => {
    if (!navigator.clipboard) {
      // Используем fallback метод, если Clipboard API недоступен
      copyToClipboardFallback(textToCopy);
      return;
    }

    navigator.clipboard.writeText(textToCopy).then(() => {
      message.success('Текст скопирован!');
    }).catch(err => {
      console.error('Failed to copy text:', err);
      // message.error('Не удалось скопировать текст');
    });
  };


  const inputProps = {
    name: 'delivery_address',
    placeholder: addressValue,
    className: b(`inputs ${addressWarning && 'warning'}`),
  };

  const handleSaveClick = () => {
    form.submit();
  };


  const handleUploadChange = ({ fileList }) => {
    if (fileList.length > 0) {
      const file = fileList[fileList.length - 1].originFileObj; // Получаем оригинальный файл
      const reader = new FileReader();

      reader.onload = e => {
        const base64String = e.target.result; // Строка Base64
        // setUserData(prevUserData => ({ ...prevUserData, photo: base64String })); // Сохраняем строку Base64 в userData
        setFileList([
          {
            uid: file.uid,
            name: file.name,
            status: 'done',
            url: base64String, // Используем Base64 строку для URL
          },
        ]);
      };

      reader.readAsDataURL(file); // Преобразуем файл в строку Base64
    }
  };


  const statusBall = ['Новичок', 'Стильняш', 'Модник', 'Шопоголик', 'Коллекционер'];
  const cachBack = ['50', '150', '250', '350', '500'];

  const userStatus = {
    "NEW": "Новичок",
    "STYLISH": "Стильняш",
    "FASHION": "Модник",
    "FASHION_ARTIST": "Шопоголик",
    "COLLECTOR": "Коллекционер"
  };
  const statuses = [
    { name: 'Новичок', orders: 0 },
    { name: 'Стильняш', orders: 3 },
    { name: 'Модник', orders: 10 },
    { name: 'Шопоголик', orders: 20 },
    { name: 'Коллекционер', orders: 30 },
  ];

  const currentOrders = orders.filter(order => order.status === 'COMPLETED');

  return (
    <div className={b()}>
      <div className={b('menu')}>
        <CustomTabs />
      </div>
      <div className={b('content')}>
        <div className={b('loyalty')}>
          <div className={b('top')}>
            <div className={b('status-points')}>
              <div className={b('s-box')}>
                <p className={b('s-title status')}>Ваш статус
                  <div className={b('tooltip')}>
                    <Info />
                    <div className={b('tooltip-info')}>
                      <div className={b('t-left')}>
                        <h4 className={b('t-title')}>Статусы</h4>
                        {statusBall.map((item) => (
                          <p key={item} className={b('t-item')}>{item}</p>
                        ))}
                      </div>
                      <div className={b('t-right')}>
                        <h4 className={b('t-title')}>Награда</h4>
                        {cachBack.map((item) => (
                          <p key={item} className={b('t-cash')}>{item} баллов</p>
                        ))}
                      </div>
                    </div>
                  </div>
                </p>
                <p className={b('s-type')}>{userStatus[userData?.status]} 💅🏻</p>
              </div>
              <div className={b('s-box')}>
                <p className={b('p-title')}>Всего баллов </p>
                <p className={b('p-count')}>{userData?.bonus_rubles}</p>
              </div>
            </div>
            <div className={b('rules')}>
              <p className={b('r-title')}>1 балл = 1 рубль</p>
              <p className={b('r-title')}>Оплачивайте баллами до 100%</p>
            </div>
          </div>

          <div className={b('bottom')}>
            {/* <div className={b('level')}/> */}
            <div className={b('progress-bar-container')}>
              <div className={b('progress-bar')}>
                {statuses.map((status, index) => (
                  <div
                    key={index}
                    className={b(`status ${currentOrders.length >= status.orders ? 'active' : ''}`)}
                    style={{ left: `${(status.orders / 30) * 100}%` }}
                  >
                    <span className={b(`status-count ${currentOrders.length >= status.orders ? 'active' : ''}`)}>{status.orders}</span>
                    <div className={b(`status-circle ${currentOrders.length >= status.orders ? 'active' : ''}`)}>

                    </div>
                    <div className={b(`status-label ${currentOrders.length >= status.orders ? 'active' : ''}`)}>
                      {status.name}
                    </div>
                  </div>
                ))}
                <div className={b('progress-line')} style={{ width: `${(currentOrders.length / 30) * 100}%` }}></div>
              </div>
            </div>

            <div className={b('benefits')}>
              <div className={b('b-item')}>
                <p className={b('circle-icon')}>💅🏻</p>
                <p className={b('text')}>250 баллов за каждый <br/>заказ по статусу Модник</p>
              </div>
              <div className={b('b-item')}>
                <p className={b('circle-icon')}>📱</p>
                <p className={b('text')}>150 баллов <br/>за каждый отзыв</p>
              </div>
              <div className={b('b-item')}>
                <p className={b('circle-icon')}>👫</p>
                <p className={b('text')}>500 баллов тебе и другу <br/>за приглашение в сервис</p>
              </div>
            </div>
          </div>
        </div>

        <div className={b('personal-link')}>
          <div className={b('left')}>
            <h3 className={b('title')}>Ваша реферальная ссылка</h3>
            <p className={b('subtitle')}>Отправьте её другу и получите каждый по 500 баллов!</p>
          </div>
          <div className={b('right')}>
            <h3 className={b('link')}>{`https://poizonrussia.ru/${userData?.id}`}</h3>
            {/* <h3 className={b('link')}>{`http://31.184.240.86/${userData?.id}`}</h3> */}
            <ButtonCustom type="icon" icon={<Copy/>}
                          onClick={() => copyToClipboard(`https://poizonrussia.ru/${userData?.id}`)}/>
                          {/* onClick={() => copyToClipboard(`http://31.184.240.86/${userData?.id}`)}/> */}
          </div>
        </div>

        <div className={b('info')}>
          <div className={b('user-info')}>
            <div className={b('user-info-block')}>
              <ImgCrop cropShape="round">
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onChange={handleUploadChange}
                  beforeUpload={() => false} // Предотвращаем автоматическую загрузку
                  showUploadList={false} // Скрываем стандартный список загрузок
                >
                  {fileList.length > 0 ? (
                    <img
                      src={fileList[0].url}
                      alt="avatar"
                      style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                    />
                  ) : (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            {/* <img src={photo} alt="photo" className={b('user-avatar')}/> */}
              <div className={b('contact')}>
                <h2 className={b('user-name')}>{userData?.full_name ? userData.full_name : 'Фио'}</h2>
                <p className={b('user-mail')}>{userData?.email ? userData.email : '*введите почту'}</p>
              </div>
            </div>
            <ButtonCustom type="text" className={b('save-info')} onClick={handleSaveClick}>Сохранить</ButtonCustom>
          </div>

          <Form
            form={form}
            className={b('form')}
            name="user"
            onFinish={handleSubmitPatch}
          >
            <div className={b('input-big')}>
              <InputCustom
                name="full_name"
                className={b('inputs')}
                label='ФИО'
                placeholder='Введите ФИО'
                rules={[validateFullName]}
              />
            </div>
            <div className={b('input-small')}>
              <InputCustom
                name="email"
                className={b('inputs')}
                label='E-mail'
                placeholder='Введите почту'
                rules={[rulesValidationEmail]}
              />
            </div>
            <div className={b('input-small')}>
              <InputCustom
                name="phone"
                // type='form'
                className={b('inputs')}
                // mask="+7(000)000 00-00"
                label='Номер телефона'
                placeholder='Номер телефона'
                disabled={true}
              />
            </div>
            <div className={b('input-big')}>
              <label className={b('address-label')}>Адрес</label>
              <AddressSuggestions
                // containerClassName={b('dadata-input')}
                defaultQuery={userData?.delivery_address}
                inputProps={inputProps}
                token="657ad176fbf10a24befc0f795bdaf02bb15e9ddb"
                value={addressValue}
                onChange={(suggestion) => {
                  setAddressValue(suggestion.value);
                  setAddressWarning(false);
                }}
              />
              {addressWarning && <span className={b('address-warning')}>Некорректный адрес</span>}
            </div>
            <div className={b('input-small')}>
              <Form.Item
                name="city"
                label='Город'
                className={b('input-select')}
              >
                <Select
                  className={b('select')}
                  placeholder='Введите Город'
                  // size={size}
                  onChange={handleCityChange}
                  showSearch
                  onSearch={handleSearchCity}
                  filterOption={false}
                >
                  {optionsCity.length > 0 && optionsCity.map((op, index) => (
                    <Option key={index} value={op.id}>{op.name}{op.showRegion ? `, ${op.region.name}` : ''}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className={b('input-small')}>
              <Form.Item
                name='pvz_cdek'
                label='Пункт СДЭК'
                className={b('input-select')}
              >
                <Select
                  disabled={!cityId}
                  className={b('select')}
                  placeholder='Выберите пункт СДЭК'
                  // size={size}
                  // onChange={onChange}
                  onMouseEnter={() => !cityId && message.info('Сначала введите Город')}
                  showSearch
                  onSearch={handleSearch}
                  filterOption={false}
                  // style={{ ...inputStyle, width: '100%' }}
                >
                  {filteredOptions.length > 0 && filteredOptions?.map((op, index) => <Option key={index} value={op.id}>{op.address}</Option>) }
                </Select>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Profile;
