import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken, removeToken, setToken } from '../../utils/token';


const BASE_URL = process.env.REACT_APP_BACKEND_HOST;


const initialState = {
  isAuth: false,
  isLoading: false,
  user: {},
  phone: null,
  token: null,
  telegramToken: null,
  verifyCode: false,
  newVerifyCode: false,
};

export const createTelegramToken = createAsyncThunk(
  '/token/createTelegramToken',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/token/create_bot_token/`);

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const checkAuthTelegram = createAsyncThunk(
  '/token/checkAuthTelegram',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/token/check_auth_bot/`, token);

      if (response.data.access_token) { setToken(response.data.access_token); }

      console.log(response, 'FROM CHECK TG AUTH');

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getPasswordForRegister = createAsyncThunk(
  '/token/getPasswordForRegister',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/token/register/`, { phone: body });

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const fetchAuth = createAsyncThunk(
  'token/fetchAuth',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/token/auth/`, body);

      setToken(response.data.access_token);

      return response.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  }
);

//TODO сделать проверку для токена в случае ошибки и вообще на все ошибки
export const fetchRegister = createAsyncThunk(
  '/token/fetchRegister',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/token/confirm-password/`, body);
      setToken(response.data.access_token);

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const fetchGetMe = createAsyncThunk(
  'users/fetchGetMe',
  async (_, { rejectWithValue }) => {
    const token = getToken();
    try {
      const response = await axios.get(`${BASE_URL}/users/me/`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      return response.data;
    } catch (e) {
      if (e.response.status === 401) {

        window.location.href = '/';
        removeToken();
      }

      return rejectWithValue(e.response.data.message);
    }
  }
);

export const fetchRefillUser = createAsyncThunk(
  'users/fetchRefillUser',
  async (body, { rejectWithValue }) => {
    const token = getToken();
    try {
      const response = await axios.patch(`${BASE_URL}/users/me/`, body, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      return response.data;
    } catch (e) {
      if (e.response.status === 401) {

        window.location.href = '/';
        removeToken();
      }

      return rejectWithValue(e.message);
    }
  }
);

export const fetchResetPassword = createAsyncThunk(
  '/users/fetchResetPassword',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/users/reset-password/`, { phone: body });

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const fetchConfirmNewPassword = createAsyncThunk(
  '/users/fetchConfirmNewPassword',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/users/reset-password-confirm/`, body);

      setToken(response.data.access_token);

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

// /api/delivery/pvz/
export const getPVZ = createAsyncThunk(
  'delivery/getPVZ',
  async (query, { rejectWithValue }) => {
    // const token = getToken();
    try {
      const response = await axios.get(`${BASE_URL}/delivery/pvz/?${query}`);

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.message);
    }
  }
);
// export const logout = createAsyncThunk('auth/logout', async function (_, { rejectWithValue }) {
//   try {
//     await SecureStore.deleteItemAsync('accessToken');
//     await SecureStore.deleteItemAsync('refreshToken');
//   } catch (error) {
//     rejectWithValue(error.message);
//   }
// });


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.phone = action.payload.phone;
      state.token = action.payload.token;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getPasswordForRegister.fulfilled,
      (state) => {
        state.verifyCode = true;
      }
    );
    builder.addCase(
      getPasswordForRegister.rejected,
      (state, action) => {
        state.verifyCode = false;
        console.error('Ошибка при получении пароля для регистрации:', state.verifyCode, action.error);
      }
    );
    builder.addCase(
      fetchResetPassword.fulfilled,
      (state) => {
        state.newVerifyCode = true;
      }
    );
    builder.addCase(
      fetchResetPassword.rejected,
      (state, action) => {
        state.newVerifyCode = false;
        console.error('Ошибка при получении нового пароля:', state.newVerifyCode, action.error);
      }
    );
    // builder.addCase(
    //   fetchConfirmNewPassword.fulfilled,
    //   (state) => {
    //     state.newVerifyCode = true;
    //   }
    // );
    builder.addCase(
      fetchConfirmNewPassword.rejected,
      (state, action) => {
        state.newVerifyCode = false;
        console.error('Ошибка при получении нового пароля:', state.newVerifyCode, action.error);
      }
    );
    builder.addCase(
      fetchRegister.fulfilled,
      (state, action) => {
        state.token = action.payload.access_token;
      }
    );
    builder.addCase(
      fetchRegister.rejected,
      (state, action) => {
        console.error('Ошибка при подтверждении пароля для регистрации:', action.error);
      }
    );
    builder.addCase(
      fetchAuth.fulfilled,
      (state, action) => {
        state.token = action.payload.access_token;
      }
    );
    builder.addCase(
      fetchAuth.rejected,
      (state, action) => {
        console.error('Ошибка при авторизации:', action.error);
      }
    );
    builder.addCase(
      createTelegramToken.fulfilled,
      (state, action) => {
        console.log(action);
        console.log(action.payload.token);
        state.telegramToken = action.payload.token;
      }
    );
    builder.addCase(
      createTelegramToken.rejected,
      (state, action) => {
        console.error('Ошибка при авторизации через телеграм:', action.error);
      }
    );
  }
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
