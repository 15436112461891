import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import ScrollToTopButton from '../../ui-kit/scrollToTopButton';


function Layout() {
  return (
    <>
      <Header />

      <Outlet />
      {/* <ScrollToTopButton/> */}
      <Footer />
    </>
  );
}

export default Layout;
