import { configureStore } from '@reduxjs/toolkit';
import userSlice from '../models/user/userSlice';
import catalogSlice from '../models/catalog/catalogSlice';
import orderSlice from '../models/order/orderSlice';


const rootReducer = {
  user: userSlice,
  catalog: catalogSlice,
  order: orderSlice,
  // products:

};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
