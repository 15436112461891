import React from 'react';
import bem from 'easy-bem';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/icons/arrow-left.svg';
import './style.scss';
import { formatPrice } from '../../utils/formatPrice';
import ButtonCustom from '../button';
import { formatDate } from '../../utils/formatDate';
import { getStatusDetails } from '../../components/orderList/data';


function OrderItem({ item }) {
  const b = bem('order-item');
  const navigate = useNavigate();
  const { color, translation } = getStatusDetails(item?.status);


  const handleOnClick = (item) => {
    // onClick(item);
    navigate(`/orders/${item.id}`);
  };


  return (
    <div className={b()}>
      <div className={b('left')}>
        <div className={b('top')}>
          <h3 className={b('title')} onClick={() => handleOnClick(item)}>
            Заказ
            <span className={b('id')}> {item.id}</span>
          </h3>
          <p className={b('status')} style={{ backgroundColor: color }}>{translation}</p>
        </div>
        <h3 className={b('data')}>
          Дата создания:
          <span className={b('data-num')}> {formatDate(item.created_at)}</span>
        </h3>
        <ButtonCustom
          type="text"
          className={b('review-mobile')}
          onClick={() => window.open(`https://t.me/TruePoisonRussiaBot?start=${item.user_phone}_${item.id}`, '_blank')}
        >
          Оставить отзыв
        </ButtonCustom>
        <p className={b('status-mobile')} style={{ backgroundColor: color }}>{translation}</p>

      </div>
      <div className={b('right')}>
        <p className={b('price')}>
          {formatPrice(item.total_cost)}
          {' '}
          ₽
        </p>
        <div className={b('btns')}>
          <ButtonCustom
            type="text"
            className={b('review')}
            onClick={() => window.open(`https://t.me/TruePoisonRussiaBot?start=${item.user_phone}_${item.id}`, '_blank')}
          >
            Оставить отзыв
          </ButtonCustom>
          <ButtonCustom
            type="text"
            icon={<Arrow />}
            className={b('to-order')}
            onClick={() => handleOnClick(item)}
          >
            К заказу
          </ButtonCustom>
        </div>
      </div>
    </div>

  );
}
//TODO добавить проверку по статусу для отзывов
export default OrderItem;
