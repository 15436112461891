import React, { useState, useEffect } from 'react';
import { ReactComponent as ArrowTop} from '../../assets/icons/arrow-up.svg';
import './style.scss'; // Подключаем стили для кнопки

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  // Показываем или скрываем кнопку в зависимости от высоты прокрутки
  const toggleVisibility = () => {
    if (window.pageYOffset > 1000) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Добавляем слушатель события прокрутки при монтировании компонента
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  // Прокрутка страницы к началу
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (

      <ArrowTop
        className={`scroll-to-top-button ${isVisible ? 'show' : ''}`}
        onClick={scrollToTop}
        aria-label="Scroll to Top"
      />
  );
}

export default ScrollToTopButton;
