import React, { useState } from 'react';
import { Form, Input, Select } from 'antd';
import MaskedInput from 'antd-mask-input';
import bem from 'easy-bem';
const BASE_URL = process.env.REACT_APP_BACKEND_HOST;

import './style.scss';
import axios from 'axios';


function InputCustom(props) {
  const b = bem('input-v2');
  const {
    className: classNameProp,
    // textarea = false,
    type = undefined,
    label = undefined,
    // withPassword = false,
    name,
    required = false,
    // validateStatus,  посмотрим нужно ли будет
    // help, ??
    mask,
    rules = [],
    options = [],
    placeholder,
    children,
    defaultValue,
    size = 'small',
    prefix,
    onChange,
    showSearch,
    ...rest
  } = props;

  const cn = classNameProp ? `${classNameProp} ${b()}` : b();
  const { Option } = Select;

  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleSearch = async (value) => {
    if (value) {
      try {
        const response = await axios.get(`${BASE_URL}/delivery/pvz/?limit=10&name=${value}`);
        setFilteredOptions(response.data.results);
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    } else {
      setFilteredOptions([]);
    }
  };

  // FOR PASSWORD
  if (type === 'form' && label && !mask) {
    return (
      <div className={`${cn} ${b('registration-input')} `}>
        <Form.Item
          className={b('form-item')}
          name={name}
          label={label}
          required={required}
        >
          <Input {...rest} type="password" className={b()} placeholder={placeholder}/>
        </Form.Item>
      </div>
    );
  }

  // FOR PHONE
  if (type === 'form' && label && mask) {
    return (
      <div className={`${cn} ${b('registration-input')} `}>
        <Form.Item
          className={b('form-item')}
          name={name}
          label={label}
          required={required}
          rules={rules}
        >
          <MaskedInput onChange={onChange} mask={mask} {...rest} placeholder={placeholder} />
        </Form.Item>
      </div>
    );
  }

  // FOR SELECT FROM ACCOUNT - СДЭК / ГОРОДА / АДРЕС??
  if (type === 'select') {
    return (
      <Form.Item
        name={name}
        label={label.includes(':') ? `${label}:` : label}
        required={required}
        rules={rules}
        className={`${cn} ${b('select')}`}
      >
        <Select
          className={`${cn} ${b('select')}`}
          placeholder={placeholder}
          size={size}
          onChange={onChange}
          // style={{ ...inputStyle, width: '100%' }}
        >
          {children || options.map((op, index) => <Option key={index} value={op.value}>{op.label}</Option>) }
        </Select>
      </Form.Item>

    );
  }
  if (type === 'selectPVZ') {
    return (
      <Form.Item
        name={name}
        label={label.includes(':') ? `${label}:` : label}
        required={required}
        rules={rules}
        className={`${cn} ${b('select-pvz')}`}
      >
        <Select
          className={`${cn} ${b('select-pvz')}`}
          placeholder={placeholder}
          size={size}
          onChange={onChange}
          showSearch={showSearch}
          onSearch={handleSearch}
          filterOption={false}
          // style={{ ...inputStyle, width: '100%' }}
        >
          {filteredOptions.length > 0 && filteredOptions?.map((op, index) => <Option key={index} value={op.id}>{op.address}</Option>) }
        </Select>
      </Form.Item>

    );
  }

  // FOR INFO FROM ACCOUNT
  if (label) {
    return (
      <Form.Item
        className={b('form-item')}
        name={name}
        label={label}
        required={required}
        rules={rules}

      >
        {/* {label} */}
        <Input className={cn} {...rest} placeholder={placeholder} />
      </Form.Item>
    );
  }

  return (
    <Input className={cn} {...rest} placeholder={placeholder} prefix={prefix} onChange={onChange}/>
  );
}

export default InputCustom;
