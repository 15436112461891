import React, { useState, useEffect } from 'react';
import bem from 'easy-bem';
import Slider from 'react-slick';
import photo from '../../../assets/images/Screenshot_reviews.png';
import { ReactComponent as Arrow } from '../../../assets/icons/arrow.svg';

import './style.scss'

// const data = [
//   {src: '../../../assets/images/Screenshot_reviews.png'},
//   {src: '../../../assets/images/Screenshot_reviews.png'},
//   {src: '../../../assets/images/Screenshot_reviews.png'},
//   {src: '../../../assets/images/Screenshot_reviews.png'},
//   {src: '../../../assets/images/Screenshot_reviews.png'},
//   {src: '../../../assets/images/Screenshot_reviews.png'},
// ]
const data = [
  {src: photo},
  {src: photo},
  {src: photo},
  {src: photo},
  {src: photo},
  {src: photo},
]

const Reviews = () => {
  const b = bem('reviews');
  const [slidesToShowConst, setSlidesToShow] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSlidesToShow(2); // Показываем 1 слайд на планшетах и меньше
      } else {
        setSlidesToShow(3); // Показываем 2 слайда на больших экранах
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Устанавливаем начальное значение

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShowConst,
    slidesToScroll: 1,
    prevArrow: <Arrow />,
    nextArrow: <Arrow />,
  };
  return (
    <div className={b()}>
      <div className={b('top')}>
        <h3 className={b('title')}>Отзывы наших покупателей</h3>
      </div>
      <Slider
        {...settings}
        className={b('slider')}
      >
        {data.map((img, i) => (
          <img key={i} className={b('img')} src={img.src} alt="img" />
        ))}
      </Slider>
      <h3 className={b('channels')}>Ещё больше можете увидеть в нашем <a className={b('link')}>телеграм-канале</a> или <a href="" className={b('link')}>VK!</a>
      </h3>
    </div>
  );
};

export default Reviews;
