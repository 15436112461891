import React from 'react';
import bem from 'easy-bem';
import screenshot1 from '../../../assets/images/Screenshot_1.png';
import screenshot2 from '../../../assets/images/Screenshot_2.png';
import screenshot3 from '../../../assets/images/Screenshot_3.png';
import screenshot4 from '../../../assets/images/Screenshot_4.png';

import './style.scss';


function CopyLink() {
  const b = bem('copy-link');

  const data = [
    {
      key: 1,
      title: <div dangerouslySetInnerHTML={{ __html: `Установи приложение <br> с сайта <a href='https://dewu.com/' target='_blank' class='${b('link')}'>dewu.com</a>` }} />,
      photo: screenshot1
    },
    {
      key: 2,
      title: (
        <div>
          Выбери нужный товар
          {' '}
          <br />
          и нажми на иконку
        </div>
      ),
      photo: screenshot2
    },
    {
      key: 3,
      title: (
        <div>
          Ещё она может
          {' '}
          <br />
          выглядеть так
        </div>
      ),
      photo: screenshot3
    },
    {
      key: 4,
      title: (
        <div>
          Скопируй URL
          {' '}
          <br />
          {' '}
          и вставь на сайт
        </div>
      ),
      photo: screenshot4
    },
  ];

  return (
    <div className={b()}>
      <h2 className={b('top')}>
        <h2 className={b('title')}>Как скопировать ссылку с Poizon?</h2>
      </h2>
      <div className={b('content')}>
        {data.map((item) => (
          <div key={item.key} className={b('item')}>
            <p className={b('key')}>{item.key}</p>
            <h3 className={b('item-title')}>{item.title}</h3>
            <img src={item.photo} alt="photo" className={b('photo')} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CopyLink;
