import React from 'react';
import bem from 'easy-bem';
import slug from 'slug';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.scss';
import { categoryNames } from '../../utils/formatCategory';

function PopUp({ data, brands = false, onClose }) {
  const b = bem('popUp');
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (data, isBrand = false) => {
    if (isBrand) {
      if (location.state?.brand !== slug(data)) {
        navigate('/catalog', { state: { brand: slug(data) } });
        onClose();
      }

      onClose();
    } else {
      // const newCategory = (data === "footwear" || data === 'accessories' || data === 'apparel') ? data : data[0];
      if (location.state?.category !== data) {
        navigate('/catalog', { state: { category: data } });
        onClose();
      }

      onClose();
    }
  };

  return (
    <div className={b()}>
      {brands ? (
        <div className={b('block')}>
          <p style={{ color: '#8F8F93' }} onClick={() => handleClick('All brands', true)}>Смотреть все</p>
          {data?.map((brand) => (
            <p key={brand.name} onClick={() => handleClick(brand.name, true)}>{brand.name.split('/')[0]}</p>
          ))}
        </div>
      ) : (
        <div className={`${b('list')} ${data?.title === 'accessories' ? 'max-h1' : ''} ${data?.title === 'footwear' ? 'max-h2' : ''}`}>
          {data?.categories.map((item) => (
            // <div className={b('list-block')}>
            <>
              <h4
                key={item.title}
                className={b('title')}
              >
                <p onClick={() => handleClick(item.title)}>{categoryNames[item.title]}</p>
                {item.subcategories.length !== 0 && <p className={b('all')} onClick={() => handleClick(item.title)}>Смотреть все</p>}
              </h4>

              {item.subcategories &&
                  item.subcategories.map((sub) => (
                    <li key={sub} onClick={() => handleClick(sub)}>{categoryNames[sub]}</li>
                  ))
              }
              </>
          ))}
        </div>
      )}
    </div>
  );
}

export default PopUp;

