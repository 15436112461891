// AuthModals.js
import React from 'react';
import ModalV2 from '../../ui-kit/modal';
import Registration from '../registration';
import Login from '../login';
import ButtonCustom from '../../ui-kit/button';
import bem from 'easy-bem';

const AuthModals = ({
  isModalOpen,
  isModalRegister,
  isModalLogin,
  warningTitle,
  handleClose,
  handleRegister,
  handleLogin,
  navigate,
  targetRoute,
  // test
}) => {
  const b = bem('header');

  return (
    <>
      <ModalV2
        open={isModalOpen}
        onClose={handleClose}
        closable={false}
        width={536}
        className={b('modal-register')}
        children={`${warningTitle}, пожалуйста, авторизируйтесь`}
        footer={(
          <div className={b('modal-btn')}>
            <ButtonCustom type="primary" onClick={handleRegister}>Зарегистрироваться</ButtonCustom>
            <ButtonCustom className={b('btn-text')} type="text" onClick={handleLogin}>Уже есть аккаунт?</ButtonCustom>
          </div>
        )}
      />
      <Registration
        isOpen={isModalRegister}
        onClose={handleClose}
        setIsModalLogin={handleLogin}
        navigate={targetRoute ? () => navigate(targetRoute) : null}
        targetRoute={targetRoute}
        // test={() => test}
      />
      <Login
        isOpen={isModalLogin}
        onClose={handleClose}
        setIsModalRegister={handleRegister}
        targetRoute={targetRoute}
        // test={() =>test}
        navigate={targetRoute ? () => navigate(targetRoute) : null}
      />
    </>
  );
};

export default AuthModals;
