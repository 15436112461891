export const formatPhoneNumber = (phoneNumber) => {
  const phone = phoneNumber.toString();
  if (!phone || phone.length !== 11) {
    return phoneNumber;
  }

  const countryCode = phone.slice(0, 1);
  const cityCode = phone.slice(1, 4);
  const firstPart = phone.slice(4, 7);
  const secondPart = phone.slice(7, 9);
  const thirdPart = phone.slice(9, 11);

  return `+${countryCode} ${cityCode} ${firstPart} ${secondPart} ${thirdPart}`;
};
