import React from 'react';
import bem from 'easy-bem';
import photo from '../../../assets/images/guarantees.png';
import '../style.scss';

const GuaranteesAndOriginality = () => {
  const b = bem('info');
  return (
    <div className={b()}>
      <h2 className={b('top')}>
        <h2 className={b('title')}>Гарантии и оригинальность</h2>
      </h2>
      <div className={b('content')}>
        <h3 className={b('subtitle')}>Проверка на оригинальность</h3>
        <p className={b('text')}>
          Ключевое преимущество PoizonRF — многоэтапная система верификации.
          Маркетплейс Poizon с которого производится закупку имеет крупную научную базу по аутентификации вещей.
          Там в лаборатории проверки на подлинность внедрено более 9 уровней проверки, контроля и досмотра.
        </p>
        <img src={photo} alt="img" className={b('img')}/>
        <p className={b('text')}>
          После проверки вещи опломбируют синей фирменной клипсой, упаковывают в фирменную коробку
          Poizon и вкладывают туда сертификат о том, что вещь прошла проверку.
          В такой комплектации товары отправляются покупателям.
        </p>
        <p className={b('text')}>
          После поступления товаров на наш склад в Москве,
          наши специалисты проводят повторную проверку товаров,
          чтобы убедиться в его качестве и оригинальности.
        </p>
        <p className={b('text')}>
          После проверки товары упаковываются в фирменные коробки PoizonRF (опционально).
          И доставляются счастливым покупателям.
        </p>
      </div>
    </div>
  );
};

export default GuaranteesAndOriginality;
