import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import bem from 'easy-bem';
import Slider from 'react-slick';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import './style.scss';
import ButtonCustom from '../../ui-kit/button';
import { getCarouselData } from '../../models/catalog/catalogSlice';


const zIndexValues = {
  block1: {
    position: 'absolute',
    top: '-40px',
    left: '-40px',
    zIndex: 100,
    transform: 'translate(-5px, -5px)'
  },
  block2: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 0
  },
  block3: {
    position: 'absolute',
    top: '-20px',
    left: '-20px',
    zIndex: 50
  }
};

function Carousel() {
  const b = bem('introduction');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const intervalIdRef = useRef(null);
  const numSlides = 3;
  const [carouselData, setCarouselData] = useState({
    newCollection: [],
    special: [],
    sales: [],
    categories: {
      newCollection: '',
      special: '',
      sales: ''
    }
  });
  const [carouselTexts, setCarouselTexts] = useState({
    newCollection: { title: '', subtitle: '' },
    special: { title: '', subtitle: '' },
    sales: { title: '', subtitle: '' }
  });

  const handleItemClick = (id) => {
    const url = `/products/${id}`;
    window.open(url, '_blank');
  };


  const nextIndex = () => (currentIndex + 1) % numSlides;
  const prevIndex = () => (currentIndex - 1 + numSlides) % numSlides;


  const startAutoScroll = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    intervalIdRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % numSlides);
    }, 3000);
  };

  const stopAutoScroll = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
  };

  const fetchData = async () => {
    try {
      const result = await dispatch(getCarouselData());
      const [newCollection, special, sales] = result.payload;

      setCarouselData({
        newCollection: newCollection.items,
        special: special.items,
        sales: sales.items,
        categories: {
          newCollection: newCollection.category[0],
          special: special.category[0],
          sales: sales.category[0]
        }
      });

      setCarouselTexts({
        newCollection: { title: newCollection.name, subtitle: newCollection.description },
        special: { title: special.name, subtitle: special.description },
        sales: { title: sales.name, subtitle: sales.description }
      });
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
    }
  };

  useEffect(() => {
    fetchData();
    startAutoScroll();

    return () => clearInterval(intervalIdRef.current);
  }, []);

  useEffect(() => {
    if (isAnimating) {
      const timeoutId = setTimeout(() => setIsAnimating(false), 300);

      return () => clearTimeout(timeoutId);
    }
  }, [isAnimating]);

  const goToCatalog = () => {
    const currentCategory = [
      carouselData.categories.newCollection,
      carouselData.categories.special,
      carouselData.categories.sales
    ][currentIndex];

    navigate('/catalog', { state: { category: currentCategory } });
  };

  const renderText = (index) => {
    const className = `${b('text')} ${index === currentIndex ? b(`text--active-${currentIndex}`) : b('text--inactive')}`;
    const texts = [carouselTexts.newCollection, carouselTexts.special, carouselTexts.sales];
    const { title, subtitle } = texts[index] || {};

    return (
      <div className={className}>
        <h1 className={b('title')}>{title}</h1>
        <p className={b('sub')}>{subtitle}</p>
        <ButtonCustom type="link" className={b('link-btn')} onClick={goToCatalog}>Перейти в каталог</ButtonCustom>
      </div>
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
    slidesToScroll: 1,
    prevArrow: <Arrow />,
    nextArrow: <Arrow />,
  };

  return (
    <div className={b()}>
      <div className={b('context')} onMouseEnter={stopAutoScroll} onMouseLeave={startAutoScroll}>
        <div className={b('mobile-top')}>
          <p className={b('page-mobile')}>
            {currentIndex + 1}
            /3
          </p>
          <div className={b('progress-bar-mobile')}>
            {[...Array(numSlides)].map((_, i) => (
              <div key={i} className={b('progress', { active: i <= currentIndex })}/>
            ))}
          </div>
        </div>
        <p className={b('page')}>
          {currentIndex + 1}
          /3
        </p>
        <div className={b('text-box')}>
          {renderText(currentIndex)}
        </div>
        <div className={b('progress-bar')}>
          {[...Array(numSlides)].map((_, i) => (
            <div key={i} className={b('progress', { active: i <= currentIndex })}/>
          ))}
        </div>
      </div>
      <div className={b('carousel')} onMouseEnter={stopAutoScroll} onMouseLeave={startAutoScroll}>
        {[carouselData.newCollection, carouselData.special, carouselData.sales].map((collection, index) => (
          <div
            key={index}
            className={b('block', {
              active: currentIndex === index,
              leaving: index === prevIndex() && isAnimating,
              inactive: currentIndex !== index,
            })}
            style={
              currentIndex === index
                ? zIndexValues.block1
                : nextIndex() === index
                  ? zIndexValues.block2
                  : prevIndex() === index
                    ? zIndexValues.block3
                    : {}
            }
          >
            <Slider
              {...settings}
              className={b('slider')}
            >
              {collection?.map((item) => (
                <div key={item.id} className={b('items')}>
                  <img onClick={() => handleItemClick(item.id)} className={b('img')} src={item.image} alt="img"/>
                </div>
              ))}
            </Slider>

          </div>
        ))}
      </div>
      <ButtonCustom type="link" className={b('link-btn-mobile')} onClick={goToCatalog}>Перейти в каталог</ButtonCustom>
    </div>
  );
}

export default Carousel;
