import React, { useState, useEffect } from 'react';
import bem from 'easy-bem';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as Telegram } from '../../assets/icons/Telegram Fill.svg';
import { ReactComponent as Whatsapp } from '../../assets/icons/Whatsapp.svg';
import ModalV2 from '../../ui-kit/modal';
import ButtonCustom from '../../ui-kit/button';

import './style.scss';
import {
  createTelegramToken, checkAuthTelegram
} from '../../models/user/userSlice';
import { getToken } from '../../utils/token';
import { message } from 'antd';


// для регистрации
function Registration({
  isOpen, onClose, setIsModalLogin, navigate, targetRoute, referralId
}) {
  const b = bem('registration');
  const dispatch = useDispatch();
  const token = getToken();

  const { telegramToken } = useSelector((state) => state.user);
  const [checking, setChecking] = useState(true);
  const [tries, setTries] = useState(0);

  const handleLogin = () => {
    onClose();
    setIsModalLogin(true);
  };

  const handleAuth = async (tg) => {
    try {
      const newWindow = window.open('', '_blank'); // Открываем пустое окно
      const action = await dispatch(createTelegramToken());
      const { token } = action.payload;

      const info = referralId ? `${token}_${referralId}` : token;
      const url = tg
        ? `https://t.me/TruePoisonRussiaBot?start=${info}`
        : `https://wa.me/79192189331?text=Зарегистрироваться%20UUID%20${info}`;

      if (newWindow) {
        newWindow.location.href = url; // Обновляем URL в открытом окне
      }

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let intervalId;

    const checkRegistration = async () => {
      if (!checking || tries === 18) { // 18 * 5 секунд = 1.5 минуты
        clearInterval(intervalId);
        if (tries >= 18) {
          // Действие, если регистрация не удалась
          message.error('Регистрация не удалась. Попробуйте снова.')
        }
        return;
      }

      if (telegramToken) {
        dispatch(checkAuthTelegram({ token: telegramToken }))
          .then((result) => {
            if (result.payload.access_token) {
              setChecking(false); // Останавливаем проверку после получения access token
              clearInterval(intervalId); // Убедимся, что интервал очищен
            } else {
              setTries((prevTries) => prevTries + 1); // Увеличиваем количество попыток
            }
          })
          .catch(() => {
            setTries((prevTries) => prevTries + 1); // Увеличиваем количество попыток при ошибке
          });
      }
    };

    intervalId = setInterval(checkRegistration, 5000); // Проверка каждые 5 секунд

    if (!isOpen){
     clearInterval(intervalId); // Очистка интервала при размонтировании компонента
    }
    return () => clearInterval(intervalId); // Очистка интервала при размонтировании компонента
  }, [telegramToken, checking, tries, dispatch]);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (token) {
      if (navigate){
        navigate();
      }

      handleClose();
    }
  }, [token]);


  return (
    <ModalV2
      className={b()}
      open={isOpen}
      onClose={handleClose}
      title="Регистрация на PoizonRF"
      footer={null}
      referral={referralId}
    >
          <p className={b('notice')}>
            Нажимая на кнопку «Зарегистрироваться», вы принимаете
            <a href="#" className={b('notice-link')}> Условия пользования </a>
            и
            <a href="#" className={b('notice-link')}> Политику конфиденциальности</a>
          </p>
          <div className={b('modal-btn')}>
            <ButtonCustom className={b('btn-tg')} type="primary-light" icon={<Telegram />} onClick={() => handleAuth(true)}>Зарегистрироваться через Telegram</ButtonCustom>
            <ButtonCustom className={b('btn-tg')} type="primary-green" icon={<Whatsapp />} onClick={() => handleAuth(false)}>Зарегистрироваться через WhatsApp</ButtonCustom>
            {!referralId && <ButtonCustom className={b('btn-account')} type="text" onClick={() => handleLogin()}>Уже есть аккуант?</ButtonCustom>}
          </div>
    </ModalV2>
  );
}

export default Registration;
