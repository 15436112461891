const apparel = [
  "featured_tops",
  "featured_jacket",
  "pants",
  "workout",
  "apparel",
  "t_shirt",
  "hoodie",
  "polo_shirt",
  "sweater",
  "shirt",
  "vest",
  "cotton_clothes",
  "jacket",
  "down_jacket",
  "baseball_uniform",
  "coat",
  "jackets",
  "windbreaker",
  "leather_jacket",
  "denim_jacket",
  "suit",
  "overralls2",
  "jeans",
  "casual_shorts",
  "underwear",
  "denim_short",
  "basketball_pants",
  "workout_clothes",
  "basketball_vest",
  "workout_pants",
  "sun_protection",
  "overalls",
  "ski_pants",
  "ski_suit",
];
const accessories = [
  "accessories",
  "bags",
  "glasses",
  "scarf",
  "bags_accessories",
  "jewelry",
  "belt",
  "watch",
  "key_chain",
  "hat",
  "brooch",
  "tie",
  "hair_accessories",
  "face_mask",
  "gold",
  "platinum",
  "diamond",
  "passport_sentence",
  "card_holder",
  "mobile_phone_bag",
  "storage_box",
  "children_bag",
  "chest_bag",
  "fanny_pack",
  "gym_bag",
  "diagonal_bag",
  "tote",
  "wallet",
  "underarm_bag",
  "backpack",
  "bags",
  "children_headband",
  "key_case",
  "brief_case",
  "luggage_tag",
  "anklet",
  "wristband",
  "cufflinks",
  "makeup_bag",
  "quartz",
  "mechanical",
  "smart",
  "alarm_clock",
  "peaked_cap",
  "berets",
  "fisherman",
  "other",
  "headband",
  "hairpin",
  "earnings",
  "bars",
  "headphone_bag",
  "necklace_bag",
  "wash_bag",
  "coin_purse",
  "sunglasses",
  "optical_frame",
  "fleece",
  "silk",
  "tape",
  "bag_peripheral",
  "necklace",
  "pendant",
  "bracelet",
  "earning",
  "ring",
  "bracelet2",
  "jewelry_accessories"
]
// const disallowedValues = ["q", "p", "ww"];

export function checkCategoryType(value) {
  if (apparel.includes(value)) {
    return true;
  } else {
    return false;
  }
}

export function checkIsAccessories(value) {
  if (accessories.includes(value)){
    return true;
  } else {
    return false;
  }
}
