import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { Form, message, Select, Spin, Switch } from 'antd';
import { AddressSuggestions } from 'react-dadata';
import axios from 'axios';
import bem from 'easy-bem';
import BasketItem from '../../ui-kit/basketItem';
import ButtonCustom from '../../ui-kit/button';
import InputCustom from '../../ui-kit/input';
import { requiredQuill, rulesValidationEmail, validateFullName } from '../../utils/validation';
import { fetchGetMe, fetchRefillUser } from '../../models/user/userSlice';
import { pluralize } from '../../utils/pluralize';
import { formatPrice } from '../../utils/formatPrice';
import { getPaymentLink, postOrder, getTotalCost } from '../../models/order/orderSlice';
import ModalV2 from '../../ui-kit/modal';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import './style.scss';


const BASE_URL = process.env.REACT_APP_BACKEND_HOST;
export const requiredFieldsByTab = {
  COURIER: ['full_name', 'email', 'delivery_address', 'city'],
  CDEK: ['full_name', 'email', 'city', 'pvz_cdek'],
  PICKUP: ['full_name', 'email']
};

function PlaceOrder() {
  const b = bem('place-order');
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [deliveryForm] = Form.useForm();
  // const [color] = useState(location?.state?.size);
  const [userData, setUserData] = useState(null);
  const [initialUserData, setInitialUserData] = useState(null);
  const [item, setItem] = useState(null);
  const [split, setSplit] = useState(false);
  const [splitHandle, setSplitHandle] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState();
  const [priceTotal, setPriceTotal] = useState(0);
  const [itemCost, setItemCost] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [warningModal, setWarningModal] = useState([false, false]);
  const [updatedUserData, setUpdatedUserData] = useState({});
  const [changedUserDataState, setChangedUserDataState] = useState({});
  const [requireForm, setRequireForm] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [errorChecked, setErrorChecked] = useState(false);
  const [addressValue, setAddressValue] = useState('');
  const [addressWarning, setAddressWarning] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [isExpressDelivery, setIsExpressDelivery] = useState(false);
  const [writeOffPoints, setWriteOffPoints] = useState(false);

  const [deliveryCost, setDeliveryCost] = useState(null);
  const [totalCostResponse, setTotalCostResponse] = useState();

  const [optionsPVZ, setOptionsPVZ] = useState([]);
  const [optionsCity, setOptionsCity] = useState([]);
  const [cityId, setCityId] = useState(localStorage.getItem('city_id') || 0);
  const [PVZId, setPVZId] = useState(0);
  const [cityCode, setCityCode] = useState(0);
  const { Option } = Select;
  const hasRunEffect = useRef(false);

  useEffect(() => {
    const data = location.state.sku;
    const isSplit = location.state.isSplit;
    setItem(data);
    setSplit(isSplit);

    if (data) {
      const findPrice = data.price || data.sku.price;
      setPriceTotal(findPrice * data?.count);
      setItemCost(findPrice);
    }
  }, [location]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await dispatch(fetchGetMe());
        setUserData(data.payload);
      } catch (error) {
        console.error('Ошибка при получении данных пользователя:', error);
      }
    };

    fetchUserData();
  }, []);


  useEffect(() => {
    if (userData) {
      const fieldsToSet = {};
      const fieldsToSetDelivery = {};
      if (userData.full_name) fieldsToSet.full_name = userData.full_name;
      if (userData.email) fieldsToSet.email = userData.email;
      if (userData.phone) fieldsToSet.phone = formatPhoneNumber(userData.phone);
      if (userData.pvz_cdek) fieldsToSetDelivery.pvz = userData.pvz_cdek.address;
      if (userData.pvz_cdek) setPVZId(userData.pvz_cdek.id);
      if (userData.city) fieldsToSetDelivery.city = userData.city;
      if (userData.delivery_address) {
        setAddressValue(userData.delivery_address);
        fieldsToSetDelivery.delivery_address = userData.delivery_address;
      }
      if (userData.city_code_cdek) setCityCode(userData.city_code_cdek);

      form.setFieldsValue(fieldsToSet);
      deliveryForm.setFieldsValue(fieldsToSetDelivery);

      setInitialUserData(userData);
    }
  }, [userData, form]);

  const getTotalOrderCost = async () => {
    if (!hasRunEffect.current) {
      hasRunEffect.current = true;
      return;
    }
    if (addressWarning) {
      return;
    }
    if (!selectedDelivery) {
      message.info('Чтобы узнать полную сумму доставки, пожалуйста, выберете способ доставки и заполните необходимые поля.')
      return;
    }
    try {
      const delivery = await deliveryForm.validateFields();
      console.log(delivery);

      const updatedUserData = {};

      const restUserData = {};

      const selectedTabFields = requiredFieldsByTab[selectedDelivery.key];
      const selectedTabFieldsExist = requiredFieldsByTab[selectedDelivery.key];

      selectedTabFields.forEach(field => {
        // console.log(userData[field]);
        // if (!userData || !userData[field]) {
        //   console.log('ksdnkjsnkndskjnksdnknds');
          if (field === 'delivery_address') {
            updatedUserData['address'] = addressValue;
          } else if (field === 'city') {
            updatedUserData[field] = delivery.city;
          }
        // }
      });

      console.log(updatedUserData, 'FROM GET TOTAL COST UPDATE ');
      console.log(restUserData, 'REST');
      // const restData =
      const order = {
        skus: [{
          item_sku: item && item.sku ? item?.sku.id : item.id, //ИЗМЕНЕНИЯ ИЗ-ЗА АКСЕССУАРОВ
          count: item && item.count
        }],
        delivery_type: selectedDelivery?.key,
        is_express_delivered: isExpressDelivery,
        bonuses: writeOffPoints ? userData?.bonus_rubles : 0,
        city_id: cityCode,
        pvz: PVZId,
        ...(promoCode.length > 0 && { promo_code: promoCode }),
        ...(updatedUserData && updatedUserData),
        // ...(restUserData && restUserData),
      }

        const response = await dispatch(getTotalCost(order));

        setDeliveryCost(response.payload.cost_delivered);
        setPriceTotal(response.payload.total_cost + response.payload.cost_delivered);
        if(!response.error){
          setTotalCostResponse(response.payload);
        }
        if (response.error){
          setTotalCostResponse(null);
        }

      console.log(order, 'ORDER FROM USEEFFETC');
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getTotalOrderCost();
  }, [
    item,
    selectedDelivery,
    cityId,
    cityCode,
    PVZId,
    addressValue,
    promoCode,
    isExpressDelivery,
    writeOffPoints,
    deliveryForm,
  ]);

  const handleIncrement = () => {
    setItem((prevItem) => {
      const updatedItem = { ...prevItem, count: prevItem.count + 1 };
      // const { price } = updatedItem;
      // setPriceTotal(price * updatedItem.count);

      return updatedItem;
    });
  };

  const handleDecrement = () => {
    setItem((prevItem) => {
      if (prevItem.count > 1) {
        const updatedItem = { ...prevItem, count: prevItem.count - 1 };
        // const { price } = updatedItem;
        // setPriceTotal(price * updatedItem.count);

        return updatedItem;
      }

      return prevItem;
    });
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setErrorChecked(false);
  };

  const inputProps = {
    name: 'delivery_address',
    // placeholder: addressValue ,
    value: addressValue,
    placeholder: 'Введите адрес' || addressValue,
    className: b(`inputs ${addressWarning && 'warning'}`),
  };


  const handleSearch = debounce(async (value) => {
    if (value) {
      try {
        const response = await axios.get(`${BASE_URL}/delivery/pvz/?limit=10&name=${value}&city=${cityId}`);
        setOptionsPVZ(response.data.results);
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    } else {
      setOptionsPVZ([]);
    }
  }, 400);

  const handleSearchCity = debounce(async (value) => {
    if (value) {
      try {
        const response = await axios.get(`${BASE_URL}/delivery/cities/?name=${value}`);

        const cities = response.data;

        const nameCounts = cities.reduce((acc, city) => {
          acc[city.name] = (acc[city.name] || 0) + 1;

          return acc;
        }, {});

        const processedCity = cities.map(city => ({
          ...city,
          showRegion: nameCounts[city.name] > 1
        }));

        setOptionsCity(processedCity);
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    } else {
      setOptionsCity([]);
    }
  }, 400);

  const handleCityChange = (value) => {
    const selectedCity = optionsCity.find(city => city.id === value); // Поиск города по id
    if (selectedCity) {
      // setAddressValue('')

      setCityId(selectedCity.id); // Обновить cityId
      setCityCode(selectedCity.code);
      deliveryForm.setFieldsValue({ pvz: ''});
      deliveryForm.setFieldsValue({
        city: `${selectedCity.name}${selectedCity.showRegion ? `, ${selectedCity.region.name}` : ''}`
      });


      if (selectedDelivery.key === 'COURIER' && !addressValue.includes(selectedCity.name)) {
        setAddressWarning(true);
      }

      getTotalOrderCost();
      setOptionsCity([]);
    }
    console.log('Selected City ID:', cityId);
  };
  const handlePVZChange = (value) => {
    const selectedPVZ = optionsPVZ.find(pvz => pvz.id === value); // Поиск города по id
    if (selectedPVZ) {
      console.log(selectedPVZ);
      setPVZId(selectedPVZ.id);
      deliveryForm.setFieldsValue({ pvz: selectedPVZ.address });

      getTotalOrderCost();
      setOptionsPVZ([]);
    }
  };


  const tabsDelivery = [
    {
      title: 'Курьер',
      key: 'COURIER',
      component: (
        <div key='COURIER'>
          <Form
            name="delivery"
            form={deliveryForm}
          >
            <Form.Item
              name="city"
              label='Город'
              className={b('input-select')}
            >
              <Select
                className={b('select')}
                placeholder='Введите Город'
                // size={size}
                onChange={handleCityChange}
                showSearch
                onSearch={handleSearchCity}
                filterOption={false}
              >
                {optionsCity.length > 0 && optionsCity.map((op, index) => (
                  <Option key={index} value={op.id}>{op.name}{op.showRegion ? `, ${op.region.name}` : ''}</Option>
                ))}
              </Select>
            </Form.Item>
            <label className={b('label')}>Адрес доставки</label>
            <AddressSuggestions
              // containerClassName={b('dadata-input')}
              defaultQuery={userData?.delivery_address}
              inputProps={inputProps}
              delay={600}
              token="657ad176fbf10a24befc0f795bdaf02bb15e9ddb"
              value={addressValue}
              onChange={(suggestion) => {
                setAddressValue(suggestion.value);
                setAddressWarning(false);
              }}
              // rules={[requiredQuill]}
            />
            {addressWarning && <span className={b('address-warning')}>Некорректный адрес</span>}
          </Form>
          {totalCostResponse && totalCostResponse?.items[0].delivered_cost_after !== false && (
            <p className={b('delivery-warning')}>стоимость оплаты будет известна при получении заказа</p>
          )}
        </div>
      )
    },
    {
      title: 'СДЭК',
      key: 'CDEK',
      component: (
        <div key='CDEK'>
          <Form
            name="delivery"
            form={deliveryForm}
          >
            <Form.Item
              name="city"
              label='Город'
              // className={b('input-select')}
            >
              <Select
                // className={b('select')}
                placeholder='Введите Город'
                // size={size}
                onChange={handleCityChange}
                showSearch
                onSearch={handleSearchCity}
                filterOption={false}
              >
                {optionsCity.length > 0 && optionsCity.map((op, index) => (
                  <Option key={index} value={op.id}>{op.name}{op.showRegion ? `, ${op.region.name}` : ''}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='pvz'
              label='Пункт СДЭК'
              className={b('input-select')}
            >
              <Select
                disabled={!cityId}
                className={b('select')}
                placeholder='Выберите пункт СДЭК'
                // size={size}
                onChange={handlePVZChange}
                onMouseEnter={() => !cityId && message.info('Сначала введите Город')}
                showSearch
                onSearch={handleSearch}
                filterOption={false}
                style={{ width: '100%' }}
              >
                {optionsPVZ.length > 0 && optionsPVZ?.map((op, index) => <Option key={index} value={op.id}>{op.address}</Option>) }
              </Select>
            </Form.Item>
          </Form>
          {totalCostResponse && totalCostResponse?.items[0].delivered_cost_after !== false && (
            <p className={b('delivery-warning')}>стоимость оплаты будет известна при получении заказа</p>
          )}
        </div>
      )
    },
    {
      title: 'Самовывоз',
      key: 'PICKUP',
      component: (
        <div key='PICKUP'>
          <Form
            name="delivery"
            form={deliveryForm}
          >
            <InputCustom
              className={b('input-delivery')}
              label="Адрес самовывоза"
              placeholder="Г. Курск. Проспект Победы Д.26"
              value="Г. Курск. Проспект Победы Д.26"
              type="text"
              disabled
            />
          </Form>
        </div>
      )
    },
  ];
  // TODO PICKUP потом по идеи будет выпадающим списоком?

  useEffect(() => {
    setSelectedDelivery(tabsDelivery[1]);
  }, []);
  const handleClick = (tab) => {
    setSelectedDelivery(tab);
    setRequireForm(false);
  };
  const renderSelectedComponent = () => {
    const selectedTab = tabsDelivery.find(tab => tab.key === selectedDelivery?.key);
    return selectedTab ? selectedTab.component : null;
  };
  const createOrder = async (userData, delivery, isSplit, newData) => {
    try {
      const data = {
        skus: [
          {
            item_sku: item.sku ? item?.sku.id : item.id, //ИЗМЕНЕНИЯ ИЗ-ЗА АКСЕССУАРОВ
            count: item.count
          }
        ],
        delivery_type: selectedDelivery.key,
        // pvz: CDEK_PVZ,
        is_express_delivered: isExpressDelivery,
        city_id: cityCode,
        pvz: PVZId,
        bonuses: writeOffPoints ? userData?.bonus_rubles : 0,
        is_split: split ? split : isSplit,
        ...(promoCode.length > 0 && { promo_code: promoCode }),
        ...(newData && newData),
        // ...delivery
      };

      const send = await dispatch(postOrder(data));

      if (send.meta.requestStatus === 'fulfilled') {
        setIsSubmitting(false);
        const link = await dispatch(getPaymentLink(send.payload[0].id));
        if (!link.error){
          window.open(link.payload, '_blank');
          navigate(`/orders/${send.payload[0].id}`);
        }
        if (link.error){
          message.error('Что-то пошло не так. Попробуйте снова.');
        }

      } else {
        message.error('Что-то пошло не так. Попробуйте снова.');
        setSplitHandle(false);
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      console.error('Ошибка при создании заказа:', error);
    }
  };

  const handleModalClose = async () => {
    try {
      const oldCityId = localStorage.getItem('city_id');
      // if ()
      console.log(updatedUserData);
      if (updatedUserData.city && updatedUserData.city_code_cdek){
        console.log('nsknsxnsjnjs sjx TRUEEEEEEEEEEEE');
        console.log(oldCityId, 'OLD ONE');
        console.log(cityId, 'NEW ONE');
        localStorage.setItem('city_id', cityId);
      }
      await dispatch(fetchRefillUser(updatedUserData));
      setUserData(prevData => {
        const newUserData = { ...prevData, ...updatedUserData };
        createOrder(newUserData, deliveryForm.getFieldsValue(), warningModal[1], changedUserDataState);

        return newUserData;
      });
    } catch (error) {
      console.error('Ошибка при обновлении данных пользователя:', error);
    } finally {
      // setIsSubmitting(false);
      setWarningModal([false, false]);
    }
  };

  const handleSubmit = async () => {
    if (!selectedDelivery) {
      setRequireForm(true);

      return;
    }
    if (!isChecked){
      setErrorChecked(true);
      return;
    }

    try {
      setSplitHandle(false);
      setIsSubmitting(true);
      // console.log(splitHandle, 'split handle from submit');
      // console.log(promoCode, 'promoCode');
      const userInfo = await form.validateFields();
      const delivery = await deliveryForm.validateFields();
      console.log(userInfo);
      console.log(delivery);

      const requiredFields = requiredFieldsByTab[selectedDelivery.key];
      const missingFields = [];

      requiredFields.forEach(field => {
        if (
          (field === 'delivery_address' && !addressValue && !userData[field]) ||
          (field === 'city' && !delivery.city && !userData[field]) ||
          (field === 'pvz_cdek' && !delivery.pvz && !userData[field])
        ) {
          missingFields.push(field);
        }
      });

      if (missingFields.length > 0) {
        setIsSubmitting(false);
        setRequireForm(true);
        return;
      }

      const updatedUserData = {};


      const selectedTabFields = requiredFieldsByTab[selectedDelivery.key];
      const changedUserData = {};
      if (userData){
        if (initialUserData.delivery_address !== addressValue) {
          changedUserData['delivery_address'] = addressValue;
        } if (initialUserData.city !== delivery.city) {
          changedUserData['city'] = delivery.city;
        } if (initialUserData?.pvz_cdek?.id !== PVZId) {
          changedUserData['pvz'] = PVZId;
        } if (initialUserData.full_name !== userInfo.full_name) {
          changedUserData['user_full_name'] = userInfo.full_name;
        } if (initialUserData.email !== userInfo.email) {
          changedUserData['user_email'] = userInfo.email;
        }
      }


      selectedTabFields.forEach(field => {
        if (!userData || !userData[field]) {
          if (field === 'delivery_address') {
            updatedUserData[field] = addressValue;
          } else if (field === 'city') {
            updatedUserData[field] = delivery.city;
          } else if (field === 'pvz_cdek') {
            updatedUserData[field] = PVZId;
          } else if (userData.city_code_cdek !== cityCode) {
            updatedUserData['city_code_cdek'] = cityCode;
          } else if (field === 'full_name') {
            updatedUserData[field] = userInfo[field];
          } else if (field === 'email') {
            updatedUserData[field] = userInfo[field];
          } else {
            updatedUserData[field] = userInfo[field];
          }
        }
      });

      if (Object.keys(updatedUserData).length > 0) {
        setUpdatedUserData(updatedUserData);
        if (Object.keys(changedUserData).length > 0) {
          setChangedUserDataState(changedUserData);
        }
        setWarningModal([true, false]);
      } else if (Object.keys(changedUserData).length !== 0) {
        await createOrder(userData, delivery, false, changedUserData);
      } else {
        await createOrder(userData, delivery, false);
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error('Ошибка при отправке данных пользователя:', error);
    }
  };

  const handleSubmitSplit = async () => {
    if (!selectedDelivery) {
      setRequireForm(true);

      return;
    }
    if (!isChecked){
      setErrorChecked(true);
      return;
    }

    try {
      setSplitHandle(true);
      setIsSubmitting(true);

      const userInfo = await form.validateFields();
      const delivery = await deliveryForm.validateFields();


      const requiredFields = requiredFieldsByTab[selectedDelivery.key];
      const missingFields = [];

      requiredFields.forEach(field => {
        if (
          (field === 'delivery_address' && !addressValue && !userData[field]) ||
          (field === 'city' && !delivery.city && !userData[field]) ||
          (field === 'pvz_cdek' && !delivery.pvz && !userData[field])
        ) {
          missingFields.push(field);
        }
      });

      if (missingFields.length > 0) {
        setIsSubmitting(false);
        setRequireForm(true);
        return;
      }

      const updatedUserData = {};
      // missingFields.forEach(field => {
      //   if (field === 'address') {
      //     updatedUserData[field] = addressValue;
      //   } else if (field === 'city') {
      //     updatedUserData[field] = delivery.city;
      //   } else if (field === 'pvz_cdek') {
      //     updatedUserData[field] = delivery.pvz;
      //   } else {
      //     updatedUserData[field] = userInfo[field];
      //   }
      // });

      const selectedTabFields = requiredFieldsByTab[selectedDelivery.key];
      const changedUserData = {};
      if (userData){
        if (initialUserData.delivery_address !== addressValue) {
          changedUserData['delivery_address'] = addressValue;
        }  if (initialUserData.city !== delivery.city) {
          changedUserData['city'] = delivery.city;
        }  if (initialUserData?.pvz_cdek?.id !== PVZId) {
          changedUserData['pvz'] = PVZId;
        }  if (initialUserData.full_name !== userInfo.full_name) {
          changedUserData['user_full_name'] = userInfo.full_name;
        } if (initialUserData.email !== userInfo.email) {
          changedUserData['user_email'] = userInfo.email;
        }
      }
      console.log(changedUserData, 'CHANGED DATATATATTATATATTA');

      selectedTabFields.forEach(field => {
        if (!userData || !userData[field]) {
          if (field === 'delivery_address') {
            updatedUserData[field] = addressValue;
          } else if (field === 'city') {
            updatedUserData[field] = delivery.city;
          } else if (field === 'pvz_cdek') {
            updatedUserData[field] = PVZId;
          } else if (userData.city_code_cdek !== cityCode) {
            updatedUserData['city_code_cdek'] = cityCode;
          } else if (field === 'full_name') {
            updatedUserData[field] = userInfo[field];
          } else if (field === 'email') {
            updatedUserData[field] = userInfo[field];
          } else {
            updatedUserData[field] = userInfo[field];
          }
        }
      });


      if (Object.keys(updatedUserData).length > 0) {
        setUpdatedUserData(updatedUserData);
        if (Object.keys(changedUserData).length > 0) {
          setChangedUserDataState(changedUserData);
        }
        setWarningModal([true, true]);
      } else if (Object.keys(changedUserData).length !== 0){
        await createOrder(userData, delivery, true, changedUserData);
      } else {
        await createOrder(userData, delivery, true);
      }
    } catch (error) {
      // setSplitHandle(false);
      setIsSubmitting(false);
      console.error('Ошибка при отправке данных пользователя:', error);
    }
  };

  const debouncedSetPromoCode = useMemo(() => debounce((value) => {
    setPromoCode(value);
  }, 600), []);

  const handleChange = (e) => {
    debouncedSetPromoCode(e.target.value);
  };

  return (
    <div className={b()}>
      <ModalV2
        className={b('modalWarning')}
        open={warningModal[0]}
        closable={false}
        onClose={null}
        title='Внесённые данные автоматически добавлены в Ваш личный кабинет'
        footer={(
          <div className={b('btn-modal')}>
            <ButtonCustom type="primary" onClick={handleModalClose}>OK</ButtonCustom>
          </div>
        )}
      />

      <div>
        <div className={b('top')}>
          <h2 className={b('info-title')}>Оформление заказа</h2>
          <p className={b('back')} onClick={() => navigate(-1)}>назад</p>
        </div>
        <div className={b('info')}>
          <div className={b('form')}>
            <div className={b('delivery', {'required': requireForm})}>
              <div className={b('d-title-box')}>
                <h2 className={b('d-title')}>Доставка</h2>
                <div className={b('express-d-box')}>
                  <p className={b('w-title')}>
                    Экспресс-доставка
                  </p>
                  <Switch className={b('switch')} onChange={() => setIsExpressDelivery(!isExpressDelivery)}/>
                </div>
              </div>

              <div className={b('d-container')}>
                {tabsDelivery.map((delivery, index) => (
                  <div
                    className={selectedDelivery?.key === delivery?.key ? `${b(`d-items-${index}`)} d-active` : b(`d-items-${index}`)}
                    key={delivery.key}
                    onClick={() => handleClick(delivery)}
                  >
                    <h3 className={b('d-sub')}>{delivery.title}</h3>
                  </div>
                ))}
              </div>

              <div>
                {selectedDelivery && renderSelectedComponent()}
                {/* {selectedDelivery?.component || <p className={b('chooseTab', {'warning' : requireForm})}>Выберите способ доставки</p>} */}
              </div>
            </div>
            <div className={b('user-info')}>
              <h2 className={b('u-title')}>Получатель</h2>
              <Form
                form={form}
                name="user-info"
              >
                <InputCustom
                  name="full_name"
                  label="ФИО"
                  placeholder="ФИО"
                  className={b('user-input')}
                  rules={[validateFullName]}
                />
                <InputCustom
                  name="email"
                  label="E-mail"
                  placeholder="E-mail"
                  className={b('user-input')}
                  rules={[rulesValidationEmail, requiredQuill]}
                />
                <InputCustom
                  name="phone"
                  label="Номер телефона"
                  placeholder="Номер телефона в виде 99999999999"
                  className={b('user-input')}
                  // rules={[rulesValidationPhone]}
                  // type="form"
                  disabled={true}
                />
              </Form>
            </div>
          </div>

          <div className={b('products')}>
            <div className={b('product-count-box')}>
              <h3>Состав заказа</h3>
              <p>1 товар</p>
            </div>
            {item && (
              <BasketItem
                key={item.sku ? item.sku.id : item.id} // ИЗМЕНЕНИЯ ИЗ-ЗА АКСЕССУАРОВ
                item={item}
                // size={location?.state?.size}
                tryy={item.count}
                // handleDelete={handleDelete}
                // onSelect={handleSelect}
                handleIncrement={handleIncrement}
                handleDecrement={handleDecrement}
              />
            )}
          </div>
        </div>
      </div>

      <div className={b('payment')}>
        <div className={b('count-price')}>
          <div className={b('total')}>
            <p className={b('total-count')}>
              Всего:
              {' '}
              {item?.count}
              {' '}
              {pluralize(item?.count, 'товар', 'товара', 'товаров')}
            </p>
            <p className={b('total-price')}>{itemCost && item.count > 1 ? `${formatPrice(itemCost * item.count)} ₽` : `${formatPrice(itemCost)} ₽`}</p>
          </div>
          {writeOffPoints && (
            <>
              <div className={b('benefit')}>
                <p className={b('b-title')}>Выгода</p>
                <p className={b('b-count')}>
                 - {userData?.bonus_rubles}
                  {' '}
                  ₽
                </p>
              </div>
              <div className={b('points')}>
                <li className={b('p-title')}>Баллы</li>
                <p className={b('p-count')}>
                 - {userData?.bonus_rubles}
                  {' '}
                  ₽
                </p>
              </div>
            </>
          )}
          {deliveryCost !== undefined && deliveryCost !== 0 && deliveryCost !== null && (
            <>
              <div className={b('benefit')}>
                <p className={b('b-title')}>Доставка</p>
                <p className={b('b-count')}>
                  + {deliveryCost}
                  {' '}
                  ₽
                </p>
              </div>
              <div className={b('points')}>
                <li className={b('p-title')}>Доставка</li>
                <p className={b('p-count')}>
                  + {deliveryCost}
                  {' '}
                  ₽
                </p>
              </div>
            </>
          )}
          {totalCostResponse && totalCostResponse?.promo_code_discount !== null && totalCostResponse?.promo_code_discount !== 0  && (
            <>
              <div className={b('benefit')}>
                <p className={b('b-title')}>Скидка</p>
                <p className={b('b-count')}>
                  - {totalCostResponse.promo_code_discount} %
                </p>
              </div>
              <div className={b('points')}>
                <li className={b('p-title')}>Промокод</li>
                <p className={b('p-count')}>
                  - {totalCostResponse.promo_code_discount} %
                </p>
              </div>
            </>
          )}
          <div className={b('sum')}>
            <p className={b('s-title')}>Итого:</p>
            <p
              className={b('s-count')}
            >
              {priceTotal ? `${formatPrice(priceTotal)} ₽` : itemCost && item.count > 1 ? `${formatPrice(itemCost * item.count)} ₽` : `${formatPrice(itemCost)} ₽`}
            </p>
          </div>
        </div>

        {userData && userData?.bonus_rubles !== 0 && (
          <div className={b('write-off')}>
            <p className={b('w-title')}>
              Списать баллы
              <span className={b('w-count')}>{userData?.bonus_rubles}</span>
            </p>
            <Switch className={b('switch')}
                    checked={writeOffPoints}
                    onChange={() => {
                      if (totalCostResponse !== undefined) {
                        setWriteOffPoints(!writeOffPoints);
                      } else {
                        message.info('Пожалуйста, заполните необходимые поля.');
                      }
                    }}
            />
          </div>
        )}

        <InputCustom
          className={b('input-promo-code')}
          name="promo-code"
          placeholder="Промокод"
          type="text"
          // value={promoCode}
          onChange={handleChange}
        />
        <div className={b('agreement')}>
          <ButtonCustom className={b('checkbox')} type="checkbox" onChange={handleCheckboxChange} checked={isChecked}/>
          <p className={b('a-title', {'warning': errorChecked})}>
            Я ознакомлен с тем, что доставка будет оплачиваться отдельно исходя из{' '}
            <a href='/delivery-and-payment' className={b('link')} >условий доставки</a>
          </p>
        </div>
        <div className={split ? b('reverse') : b('btn-block')}>
          {!split &&
            <ButtonCustom
            type='link'
            className={b('pay')}
            onClick={handleSubmit}
            disabled={isSubmitting}
           >
            {isSubmitting && !splitHandle ? <Spin className={b('spin')} /> : 'Оплатить'}
           </ButtonCustom>}
          {(!priceTotal || priceTotal < 40000) && <ButtonCustom
            type={split ? 'link' : 'text'}
            className={split ? b('pay') : b('split')}
            onClick={handleSubmitSplit}
            disabled={isSubmitting}
          >
            {isSubmitting && splitHandle ? <Spin className={b('spin', {'isClicked': splitHandle, 'isSingle': split })} /> : ' Оплатить по частям'}
          </ButtonCustom>}
        </div>
      </div>
    </div>
  );
}

export default PlaceOrder;
