import React from 'react';
import bem from 'easy-bem';
import { ReactComponent as Telegram } from '../../../assets/icons/telegram-icon.svg';
import { ReactComponent as Youtube } from '../../../assets/icons/youtube-icon.svg';
import { ReactComponent as Vk } from '../../../assets/icons/vk-icon.svg';
import { ReactComponent as Whatsapp } from '../../../assets/icons/whatsapp-icon.svg';
import photo from '../../../assets/images/poizonImg.png';

import './style.scss';
import NeedHelp from '../../../ui-kit/needHelp';


function Contact() {
  const b = bem('contact');


  return (
    <div className={b()}>
      <div className={b('top')}>
        <h2 className={b('title')}>Контакты</h2>
      </div>

      <div className={b('box')}>
        <div className={b('info-contact')}>
          <div className={b('info')}>
            <p className={b('phone')}>+7 923 939 00 88</p>
            <p className={b('email')}>work@poizonrf.ru</p>
            <p className={b('address')}>г. Москва, улица Советская, дом 18</p>
          </div>
          <div className={b('social-media')}>
            <Telegram className={b('icon')} />
            <Youtube className={b('icon')} />
            <Whatsapp className={b('icon')} />
            <Vk className={b('icon')} />
          </div>
        </div>
        <div className={b('line')} />
        <div className={b('text')}>
          С самого первого дня работы нашей команды, мы решали одну единственную задачу —
          <p className={b('br')} />
          создать безопасный сервис для покупателей, который
          {' '}
          <strong>решает их проблему и дает качественный результат</strong>
        </div>
      </div>

      <img src={photo} alt="photo" className={b('photo')} />

      <div className={b('connect')}>
        <div className={b('feedback')}>
          <h3 className={b('f-title')}>
            Хотите оставить
            <br />
            {' '}
            нам обратную связь?
          </h3>
          <p className={b('f-subtitle')}>
            Напиши нам,
            <br />
            мы будем рады ответить!
          </p>
        </div>
        <div className={b('form-box')}>
          <NeedHelp />
        </div>
      </div>
    </div>
  );
}

export default Contact;
