// AWAITING_PAYMENT - не оплачен \желтый
// FAILED_PAYMENT - ошибка оплаты \класный
// PAID - полностью оплачен \
// SPLIT_PAID - оплачена половина \ желтый
// PROCESSING - в обработке \ фио
// AWAITING_SPLIT_PAID - ожидает полную оплату \ желтый
// AWAIT_DELIVERED - ожидает отправку \ фио
// ACCEPT_DELIVERY - принят в доставку \ фиол
// ON_THE_ROAD - в пути \ фиолетовый
// AWAITING_RECEIPT - ожидает получения \ зеленый
// COMPLETED - завершен \ серый
// CANCEL - отменен \красный

// Жёлтые — привлекают внимание, так как требуют действия пользователя
//
// Красный показывает, что заказ отменён и ждать его не стоит / нужно предпринять действия, чтобы заказать снова, например
//
// Фиолетовый говорит о том, что заказ в процессе обработки/доставки, то есть активен
//
// Завершённый — серый, потому что он уже неактивен и ничего не требует ни с какой стороны
//
// Готов к выдаче — зелёный, потому что это положительная новость + требует действий от пользователя
const deliveryStatusTranslations = {
  AWAITING_PAYMENT: 'Ожидание оплаты',
  FAILED_PAYMENT: 'Ошибка оплаты',
  PAID: 'Оплачен полностью',
  SPLIT_PAID: 'Ожидание второго платежа',
  PROCESSING: 'В обработке',
  AWAITING_SPLIT_PAID: 'Ожидает полную оплату',
  AWAIT_DELIVERED: 'Ожидает отправку',
  ACCEPT_DELIVERY: 'Принят в доставку',
  ON_THE_ROAD: 'В пути',
  AWAITING_RECEIPT: 'Товар готов к выдаче',
  COMPLETED: 'Завершенный',
  CANCEL: 'Отменен'
};

const deliveryStatusColors = {
  AWAITING_PAYMENT: '#EEBF19',
  FAILED_PAYMENT: '#D44343',
  PAID: '#7364FF',
  SPLIT_PAID: '#EEBF19',
  PROCESSING: '#7364FF',
  AWAITING_SPLIT_PAID: '#EEBF19',
  AWAIT_DELIVERED: '#7364FF',
  ACCEPT_DELIVERY: '#7364FF',
  ON_THE_ROAD: '#7364FF',
  AWAITING_RECEIPT: '#43D46C',
  COMPLETED: '#9E9E9E',
  CANCEL: '#D44343'
};

export const getStatusDetails = (status) => {
  const color = deliveryStatusColors[status] || 'white';
  const translation = deliveryStatusTranslations[status] || status;

  return { color, translation };
};
