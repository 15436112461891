import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import bem from 'easy-bem';
import ButtonCustom from '../button';

import './style.scss';
import { removeToken } from '../../utils/token';


const tabsItems = [
  { title: 'Профиль', key: '1', path: '/profile' },
  { title: 'Избранное', key: '2', path: '/favorites' },
  { title: 'Список заказов', key: '3', path: '/orders' }
];
function CustomTabs({onClose}) {
  const b = bem('tabs');
  const location = useLocation();
  const navigate = useNavigate();

  console.log(onClose);
  const logout = () => {
    navigate('/');
    removeToken();
    if (onClose) {
      onClose(); // Закрываем меню при выходе из аккаунта
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (onClose) {
      onClose(); // Закрываем меню при навигации
    }
  };

  const isPathActive = (path) => {
    return location.pathname.includes(path);
  };


  return (
    <div className={b()}>
      <h2 className={b('title')}>Личный кабинет</h2>

      <div className={b('item-box')}>
        {tabsItems.map((item) => (
          <ButtonCustom
            key={item.key}
            type={isPathActive(item.path) ? 'primary' : 'primary-gray'}
            onClick={() => handleNavigation(item.path)}
            className={b('btn')}
          >
            {item.title}
          </ButtonCustom>
        ))}
      </div>

      <ButtonCustom className={b('btn-logout')} type="text" onClick={() => logout()}>Выйти из аккаунта</ButtonCustom>
      <p className={b('mobile-logout')} onClick={() => logout()}>Выйти из аккаунта</p>
    </div>
  );
}

export default CustomTabs;
