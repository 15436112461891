import React, { useState } from 'react';
import bem from 'easy-bem';

import '../style.scss';
import './style.scss';
import { formatPrice } from '../../../utils/formatPrice';


export const mockSizesData = [
  {
    title: 'EU',
    content: [
      { size: '36', price: null },
      { size: '37', price: 69900 },
      { size: '38', price: 74900 },
      { size: '39', price: null },
      { size: '40', price: 71900 },
      { size: '41', price: null },
      { size: '42', price: 75900 },
      { size: '43', price: 76900 },
      { size: '44', price: 78900 },
      { size: '45', price: 79900 },
      { size: '46', price: 80000 }
    ]
  },
  {
    title: 'US',
    content: [
      { size: '5', price: null },
      { size: '6', price: 64900 },
      { size: '7', price: 69900 },
      { size: '8', price: null },
      { size: '9', price: 70900 },
      { size: '10', price: 72900 },
      { size: '11', price: 75900 },
      { size: '12', price: 76900 },
      { size: '13', price: 78900 },
      { size: '13.5', price: 78900 },
      { size: '14', price: null },
      { size: '15', price: null },
    ]
  },
  {
    title: 'UK',
    content: [
      { size: '4', price: null },
      { size: '5', price: 63900 },
      { size: '6', price: 68900 },
      { size: '6.5', price: 68900 },
      { size: '7', price: null },
      { size: '8', price: 71900 },
      { size: '9', price: 74900 },
      { size: '10', price: 77900 },
      { size: '11', price: 79900 },
      { size: '12', price: 79900 },
      { size: '13', price: null }
    ]
  },
  {
    title: 'RU',
    content: [
      { size: '35', price: null },
      { size: '36', price: 65900 },
      { size: '37', price: 69900 },
      { size: '38', price: 74900 },
      { size: '39', price: null },
      { size: '40', price: 71900 },
      { size: '41', price: 75900 },
      { size: '42', price: 78900 },
      { size: '43', price: 78900 },
      { size: '44', price: null },
      { size: '45', price: 80000 }
    ]
  },
];

function HowToChooseSize() {
  const b = bem('info');
  const m = bem('table');
  const [activeTab, setActiveTab] = useState(mockSizesData[0].title);
  const [selectedSize, setSelectedSize] = useState(mockSizesData[0].content[2].size);

  const handleTabClick = (title) => {
    setActiveTab(title);
    setSelectedSize(null);
  };

  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };

  const activeContent = mockSizesData.find((tab) => tab.title === activeTab).content;

  return (
    <div className={b()}>
      <h2 className={b('top')}>
        <h2 className={b('title')}>Как выбрать размер обуви?</h2>
      </h2>
      <div className={b('content')}>
        <h3 className={b('subtitle')}>Сравнивайте размер по размерной сетке</h3>
        <p className={b('text')}>
          В PoizonRF представлен большой выбор кроссовок и одежды разных брендов.
          При покупках онлайн, бывает сложно сделать правильный выбор размера.
          <div className={b('br')}>
            <br />
          </div>
          Для того что бы верно определить размер, советуем смотреть полную размерную сетку,
          она идет индивидуально к каждой модели обуви.
        </p>
        <div className={m('sizes-grid')}>
          <div className={m('tab-header')}>
            {mockSizesData.map((tab) => (
              <div
                key={tab.title}
                className={m(`tab ${activeTab === tab.title ? 'active' : ''}`)}
                onClick={() => handleTabClick(tab.title)}
              >
                {tab.title}
              </div>
            ))}
          </div>

          <div className={m('tab-content')}>
            {activeContent.map((item, index) => (
              <div
                key={index}
                className={m(`tab-item ${item.price ? '' : 'not-available'} ${item.size === selectedSize ? 'choose' : ''}`)}
                onClick={() => {
                  if (item.price) {
                    handleSizeClick(item.size);
                  }
                }}
              >
                <p className={m('tab-item-size')}>{`${activeTab} ${item.size}`}</p>
                <p className={m('tab-item-price')}>
                  {item.price ? `${formatPrice(item.price)} ₽` : 'Нет в наличии'}
                </p>
              </div>
            ))}
          </div>
        </div>
        <p className={b('text')}>
          Основные размеры на нашем сайте - это EUR (Европейский). Дополнительно, для справки и сравнения в таблицах,
          пишутся размеры в US/UK/FR/CHN/JP и сантиметрах. Обозначения размеров: EUR - европейский, US- американский,
          UK- английский,
          FR- французский, СHN - китайский, JP-японский.
          <div className={b('br')}>
            <br />
          </div>
          Примечание:
          <div className={b('br')}>
            <br />
          </div>
          EUR - на 1 деление обозначается больше русского.
          Например, если у вас русский 42, значит EUR - 43.
          <div className={b('br')}>
            <br />
          </div>
          FR - в основном встречается у Adidas, имеет дольные значения (например 42 2/3).
          Советуем в обуви Adidas ориентироваться на US/UK или прошлый опыт носки обуви бренда.
          <div className={b('br')}>
            <br />
          </div>
          CHN - очень похожи на значения в сантиметрах, но на самом деле отличаются от обычных
          сантиметров стельки. Советуем не ориентироваться на китайский размер.
          <div className={b('br')}>
            <br />
          </div>
          US - самое универсальное значения для определения
          размера в любой обуви, в 97% является самым точным.
        </p>

        <h3 className={b('subtitle-mt')}>Большемерит или маломерит обувь на PoizonRF?</h3>
        <p className={b('text')}>
          Вся продукция представленная на нашем сайте идентична той, которую мы привыкли встречать в магазинах в России,
          Европы или Америки.
          Это оригинальная продукция, размеры которой не зависят от страны реализации. Обувь большемерит или маломерит
          только в том случае, если это присуще самой модели.
          Советуем смотреть обзоры или читать отзывы в интернете, касаемо особенностей размеров той или
          иной продукции, чтобы быть уверенным в выборе.
        </p>

        <h3 className={b('subtitle-mt')}>Какие бывают размерные ряды и их особенности?</h3>
        <p className={b('text')}>
          Размерные ряды и таблицы бывают разными в зависимости от моделей.
          Одни и те же кроссовки могут быть в мужской сетке, женской или детской/подростковой.
          Мужская сетка и женская отличаются по значениям (US/UK/сантиметрам) в таблице размеров, иногда они прописаны,
          как M - мужские, W - женские. Подростковые/детские модели обозначаются на Poizon значком - "GS" или "BP".
          Такие модели отличаются не только маленьким размерным рядом, но и используемыми материалами в производстве, а
          также посадкой по ноге.
        </p>
      </div>
    </div>
  );
}

export default HowToChooseSize;
