import React, { useRef, useState } from 'react';
import bem from 'easy-bem';
import { Form, message, Spin } from 'antd';
import { ReactComponent as SneakerBlue } from '../../assets/icons/9af17a9cfea74cb593cc6e847fb0732c 1 (1).svg';

import './style.scss';
import InputCustom from '../input';
import ButtonCustom from '../button';
import MaskedInput from 'antd-mask-input';
import AuthModals from '../../components/authModals';
import useAuthCheck from '../../utils/useAuthCheck';
import { useDispatch } from 'react-redux';
import { postQuestion } from '../../models/order/orderSlice';


function NeedHelp() {
  const b = bem('need-help');
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    isModalOpen,
    isModalRegister,
    isModalLogin,
    warningTitle,
    checkAuth,
    handleRegister,
    handleLogin,
    handleClose,
  } = useAuthCheck();

  const handleSubmit = (values) => {
    checkAuth(async () => {
      // console.log(values);
      setLoading(true)
      const response = await dispatch(postQuestion(values));
      // console.log(response);
      if (!response.error) {
        message.success('Ваш вопрос отправлен! Администратор свяжется с вами в ближайшее время.');
        formRef.current.resetFields();
        setLoading(false)
      } else {
        setLoading(false)
        message.error('Заполните все обязательные поля.')
        // formRef.current.resetFields();
      }

    }, 'Чтобы отправить вопрос');
  };

  return (
    <div className={b()}>
      <div className={b('top')}>
        <div className={b('text')}>
          <h3 className={b('title')}>Нужна помощь?</h3>
          <p className={b('subtitle')}>Напиши нам и мы обязательно поможем!</p>
        </div>
        <SneakerBlue className={b('img')} />
      </div>
      <Form
        name="help"
        onFinish={handleSubmit}
        ref={formRef}
        className={b('form')}
      >
        <Form.Item
          name="title"
        >
          <MaskedInput
            type="form"
            mask="+7(000)000 00-00"
            placeholder="Телефон"
            required
            className={b('input')}
          />
        </Form.Item>
        <Form.Item
          name="content"
        >
          <InputCustom
            placeholder="Ваш вопрос"
            required
            className={b('input')}
          />
        </Form.Item>

        <ButtonCustom className={b('btn')} htmlType="submit">{loading ? <Spin/> : 'Отправить'}</ButtonCustom>
      </Form>

      <AuthModals
        isModalOpen={isModalOpen}
        isModalRegister={isModalRegister}
        isModalLogin={isModalLogin}
        warningTitle={warningTitle}
        handleClose={handleClose}
        handleRegister={handleRegister}
        handleLogin={handleLogin}
      />
    </div>
  );
}

export default NeedHelp;
