import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import bem from 'easy-bem';
import axios from 'axios';
import slug from 'slug';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { ReactComponent as FiltersIcon } from '../../assets/icons/sliders.svg';
import { categoryNames } from '../../utils/formatCategory';
import ButtonCustom from '../../ui-kit/button';
import InputCustom from '../../ui-kit/input';
import { sort } from '../filters/data';
import Filters from '../filters';
import { getCatalogFiltered, getCategoryName } from '../../models/catalog/catalogSlice';

import './style.scss';
import SearchByLink from '../searchByLink';
import ScrollToTopButton from '../../ui-kit/scrollToTopButton';
import Card from '../../ui-kit/card';
import { checkCategoryType, checkIsAccessories } from '../../utils/getCategoryTypeForSize';

function CatalogParent() {
  const b = bem('catalog-parent');
  const dispatch = useDispatch();
  const location = useLocation();
  const [category, setCategory] = useState(location?.state?.category);
  const [brand, setBrand] = useState(location?.state?.brand);
  const [search, setSearch] = useState(location?.state?.search);
  const [catalogName, setCatalogName] = useState((categoryNames[category] || category) || brand || search || 'Каталог');
  const [catalogData, setCatalogData] = useState([]);
  const [total, setTotal] = useState(0);
  const [nextPage, setNextPage] = useState('');
  const [itemsPerPage] = useState(12);
  const [sortOption, setSortOption] = useState(sort[2].value);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [activeSizeTab, setActiveSizeTab] = useState('apparel');
  const [chosenSize, setChosenSize] = useState(null);
  const [range, setRange] = useState([2000, 300000]);
  const [rangeComplete, setRangeComplete] = useState([0, 0]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [catalogLoading, setCatalogLoading] = useState(false);
  const [apparel, setApparel] = useState(false);
  const [allSizeFilters, setAllSizeFilters] = useState(false);
  const [noSizes, setNoSizes] = useState(false);

  const getRussianCategoryName = async () => {
    try {
      const response = await dispatch(getCategoryName(category));
      // const [catalogName, setCatalogName] = useState((categoryNames[category] || category) || brand || search || 'Каталог');

      console.log(response);
      if (!response.error){
        setCatalogName(response.payload.title);
      }
    } catch {
      throw new Error();
    }
  }

  useEffect(() => {
    setApparel(checkCategoryType(location.state.category));
    setNoSizes(checkIsAccessories(location.state.category));
    getRussianCategoryName();
  }, []);

  const handleCleanAllFilters = () => {
    // if (sortOption !== '') {
    //   setSortOption('');
    // }
    if (selectedBrands.length > 0) {
      setSelectedBrands([]);
    }
    if (chosenSize !== null) {
      setChosenSize(null);
    }
    if (range[0] !== 2000 || range[1] !== 300000) {
      setRange([2000, 300000]);
    }
    if (rangeComplete[0] !== 0 || rangeComplete[1] !== 0) {
      setRangeComplete([0, 0]);
    }
  };

  const getCatalogType = () => {
    if (location.state?.category) {
      if (location.state.category !== category) {
        setCategory(location.state.category);
        setCatalogName(categoryNames[location.state.category] || location.state.category);
        setApparel(checkCategoryType(location.state.category));
        setNoSizes(checkIsAccessories(location.state.category));
        if (brand !== '') {
          setBrand('');
          handleCleanAllFilters();
        }
        if (search !== '') {
          setSearch('');
          handleCleanAllFilters();
        }
      }
    }
    if (location.state?.brand) {
      if (location.state.brand !== brand) {
        setCatalogName(location.state.brand);
        setBrand(location.state.brand);
        if (category !== '') {
          setCategory('');
          handleCleanAllFilters();
        }
        if (search !== '') {
          setSearch('');
          handleCleanAllFilters();
        }
      }
    }
    if (location.state?.search){
      if (location.state.search !== search){
        setCatalogName(location.state.search);
        setSearch(location.state.search);
        if (category !== '') {
          setCategory('');
          handleCleanAllFilters();
        }
        if (brand !== '') {
          setBrand('');
          handleCleanAllFilters();
        }
      }
    }

    handleCleanAllFilters();
  };


  useEffect(() => {
    setIsFiltersOpen(false);
    setCatalogData([]);
    setCatalogLoading(true);
    getCatalogType();
  }, [location.state]);

  useEffect(() => {
    const fetchCatalogData = async () => {
      try {
        let query = '';
        if (brand && brand !== 'all-brands') {
          query = `brand=${brand}&limit=${itemsPerPage}`;
        } else if (category && category !== 'all') {
          query = `category=${category}&limit=${itemsPerPage}`;
        } else if (search && search !== '') {
          query = `search=${search}&limit=${itemsPerPage}`;
        } else {
          query = `limit=${itemsPerPage}`;
        }

        if (sortOption) {
          query += `&ordering=${sortOption}`;
        }

        if (selectedBrands.length > 0) {
          const slugifiedBrands = selectedBrands.map((brand) => slug(brand));
          const brandQueryString = slugifiedBrands.map((brand) => `brand=${brand}`).join('&');
          query += `&${brandQueryString}`;
        }

        if (chosenSize !== null) {
          query += `&size_type=${activeSizeTab}&size_value=${chosenSize}`;
        }

        if (rangeComplete[0] !== 0 || rangeComplete[1] !== 0) {
          query += `&from_price=${rangeComplete[0]}&to_price=${rangeComplete[1]}`;
        }

        setCatalogLoading(true);
        const result = await dispatch(getCatalogFiltered(query));
        if (result.payload) {
          setCatalogLoading(false);
          setCatalogData(result.payload.results);
          setNextPage(result.payload.next);
          setTotal(result.payload.count);
        } else {
          setCatalogLoading(false)
          console.error('Unexpected response structure:', result);
        }
      } catch (e) {
        setCatalogLoading(false);
        console.error('Error fetching catalog:', e);
      }
    };

    fetchCatalogData();
  }, [sortOption, selectedBrands, chosenSize, rangeComplete, category, brand, search]);

  const handleSortChange = (value) => {
    setSortOption(value);
  };

  const handleLoadMore = async () => {
    if (nextPage) {
      try {
        setLoading(true);
        const result = await axios.get(nextPage);
        if (result.data) {
          setCatalogData((prevData) => [...prevData, ...result.data.results]);
          setNextPage(result.data.next);
          setLoading(false);
        } else {
          setLoading(false);
          console.error('Unexpected response structure:', result);
        }
      } catch (e) {
        setLoading(false);
        console.error('Error loading more data:', e);
      }
    }
  };

  const handleBrandChange = (brand) => {
    setSelectedBrands((prevSelected) => (prevSelected.includes(brand)
      ? prevSelected.filter((b) => b !== brand)
      : [...prevSelected, brand]));
  };

  const handleChangeSIzeType = (type) => {
    setActiveSizeTab(type);
    setChosenSize(null);
  };

  const handleChoseSize = (value) => {
    setChosenSize(value);
  };

  const handleSetRange = (value) => {
    setRange(value);
  };

  const handleSetRangeComplete = (value) => {
    setRangeComplete(value);
  };

  useEffect(() => {
    setActiveSizeTab(apparel ? 'apparel' : 'EU');
  }, [apparel]);


  return (
    <>
      <div className={b()}>
        <div className={b('breadcrumbs')}>
          <Link to='/' className={b('breadcrumbs-item')}>Главная</Link>
          <p className={b('b-delimiter')}>/</p>
          <p className={b('breadcrumbs-last')}>{catalogName}</p>
        </div>
        <div className={b('top-filters')}>
          <div className={b('left')}>
            <h2 className={b('category-name')}>{catalogName}</h2>
            <p className={b('count')}>
              Найдено
              {' '}
              {total}
            </p>
          </div>
          <div className={b('right')}>
            <ButtonCustom
              type='text'
              className={b('btn-filters')}
              icon={<FiltersIcon/>}
              onClick={() => setIsFiltersOpen(!isFiltersOpen)}
            >
              {isFiltersOpen ? 'Скрыть фильтры' : 'Показать фильтры'}
            </ButtonCustom>
            <InputCustom
              label='Сортировка:'
              name="sort"
              options={sort}
              value={sortOption}
              placeholder='Популярные'
              onChange={handleSortChange}
              type="select"
              className={b('sort-select')}
            />
          </div>
        </div>
        <div className={b('content', {
          'filters-open': isFiltersOpen,
          'filters-close': !isFiltersOpen
        })}>
          <div className={b('slide-filters', { 'visible': isFiltersOpen })}>
            <Filters
              selectedBrands={selectedBrands}
              onChangeBrand={handleBrandChange}
              activeSizeTab={activeSizeTab}
              chosenSize={chosenSize}
              onChangeChosenSize={handleChoseSize}
              onChangeSizeType={handleChangeSIzeType}
              range={range}
              setRange={() => setRange}
              onChangeRangeComplete={handleSetRangeComplete}
              onChangeRange={handleSetRange}
              handleCleanAllFilters={handleCleanAllFilters}
              apparel={apparel}
              noSizes={noSizes}
              category={category}
            />
          </div>
          {/* )} */}
          <div className={b('catalog', {
            'full-width': !isFiltersOpen,
            'part-width': isFiltersOpen
          })}>
            <div className={b('catalog-box')}>
              {catalogData.length > 0 ? (
                  <div className={b('catalog-items', {'tablet' : isFiltersOpen})}>
                    {catalogData.map((item, index) => (
                      <Card categoryOrBrand={catalogName} key={index} item={item}/>
                    ))}
                  </div>
                )
                : (
                  <div className={catalogLoading && b('centred-spin')}>
                    {catalogLoading ?
                      <Spin className={'catalog-spin'}/>
                      :
                      <h1 className={b('nothing-found')}>К сожалению, ничего не нашлось</h1>
                    }
                  </div>

                )}
            </div>
            {nextPage && (
              <button onClick={handleLoadMore} className={b('more')}>{loading ? <Spin className={b('spin')}/> : 'Показать ещё'}</button>
            )}
          </div>
        </div>
        <ScrollToTopButton/>
      </div>
      <SearchByLink/>
    </>


  );
}

export default CatalogParent;
