import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken, removeToken } from '../../utils/token';
import { createTelegramToken, fetchAuth } from '../user/userSlice';
import { message } from 'antd';
// import { setToken } from '../../utils/token';


const BASE_URL = process.env.REACT_APP_BACKEND_HOST;

const initialState = {

  orders: [],
  orderCreate: false,
  isLoading: false,
}

export const getOrdersList = createAsyncThunk(
  'order/getOrdersList',
  async (_, { rejectWithValue }) => {
    const token = getToken();
    try {
      const response = await axios.get(`${BASE_URL}/orders/`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      return response.data;
    } catch (e) {
      if (e.response.status === 401) {

        window.location.href = '/';
        removeToken();
      }

      return rejectWithValue(e.message);
    }
  }
);

export const postOrder = createAsyncThunk(
  'order/postOrder',
  async (body, { rejectWithValue }) => {
    const token = getToken();
    try {
      const response = await axios.post(`${BASE_URL}/orders/`, body, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      return response.data;
    } catch (e) {
      if (e.response.status === 401) {

        window.location.href = '/';
        removeToken();
      }

      return rejectWithValue(e.message);
    }
  }
);

export const postQuestion = createAsyncThunk(
  'order/postQuestion',
  async (body, { rejectWithValue }) => {
    const token = getToken();
    try {
      const response = await axios.post(`${BASE_URL}/support-messages/`, body, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      return response.data;
    } catch (e) {
      if (e.response.status === 401) {

        window.location.href = '/';
        removeToken();
      }

      message.error(e.response.data[0].promo_code || e.message);

      return rejectWithValue(e.message);
    }
  }
);


export const getSingleOrder = createAsyncThunk(
  'order/getSingleOrder',
  async (id, { rejectWithValue }) => {
    const token = getToken();
    try {
      const response = await axios.get(`${BASE_URL}/orders/${id}`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      return response.data;
    } catch (e) {
      if (e.response.status === 401) {

        window.location.href = '/';
        removeToken();
      }

      return rejectWithValue(e.message);
    }
  }
);

export const getPaymentLink = createAsyncThunk(
  'order/getPaymentLink',
  async (id, { rejectWithValue }) => {
    const token = getToken();
    try {
      const response = await axios.get(`${BASE_URL}/orders/${id}/payment_link/`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      return response.data;
    } catch (e) {
      if (e.response.status === 401) {

        window.location.href = '/';
        removeToken();
      }

      return rejectWithValue(e.message);
    }
  }
);

export const getTotalCost = createAsyncThunk(
  'order/getPaymentLink',
  async (body, { rejectWithValue }) => {
    const token = getToken();
    try {
      const response = await axios.post(`${BASE_URL}/orders/get_total_cost/`, body, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      return response.data;
    } catch (e) {
      if (e.response.status === 401) {

        window.location.href = '/';
        removeToken();
      }
      if (e.response.data['promo_code']) {
        message.error(e.response.data['promo_code']);
      }

      return rejectWithValue(e.message);
    }
  }
);




const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(
      getPaymentLink.rejected,
      (state, action) => {
        console.error('Ошибка при авторизации:', action.error);
      }
    );
    builder.addCase(
      getPaymentLink.fulfilled,
      (state, action) => {
        state.orderCreate = true;
      }
    );
    builder.addCase(
      postOrder.rejected,
      (state, action) => {
        console.error('Ошибка при авторизации:', action.error);
      }
    );
    builder.addCase(
      postOrder.fulfilled,
      (state, action) => {
        state.orders = action.payload;
      }
    );
  }
})

export default orderSlice.reducer;
