import React from 'react';
import bem from 'easy-bem';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as Telegram } from '../../assets/icons/telegram-icon.svg';
import { ReactComponent as Youtube } from '../../assets/icons/youtube-icon.svg';
import { ReactComponent as Vk } from '../../assets/icons/vk-icon.svg';
import { ReactComponent as Whatsapp } from '../../assets/icons/whatsapp-icon.svg';
import './style.scss';


const sectionItems = [
  // { label: 'Sale', key: '1', component: <NotFound /> },
  { label: 'Обувь', key: '2', data: 'footwear' },
  { label: 'Одежда', key: '3', data: 'apparel' },
  { label: 'Аксуссуары', key: '4', data: 'accessories' },
  { label: 'Бренды', key: '5', data: '' }
];
//TODO сказать что объединила страницы оплаты и доставки .... не хватает страниц
const infoItems = [
  { label: 'Контакты', key: '1', path: 'contact' },
  { label: 'Доставка и Оплата', key: '2', path: 'delivery-and-payment' },
  { label: 'Гарантии и оригинальность', key: '3', path: 'guarantees-and-originality' },
  { label: 'Отзывы', key: '4', path: 'reviews' },
  // { label: 'Возврат ?', key: '5', path: 'dmdkf' },
  { label: 'Помощь', key: '6', path: 'help' }
];
function Footer() {
  const b = bem('footer');
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (data) => {
    window.scrollTo(0, 0);
    if (location.state?.category !== data) {
      navigate('/catalog', { state: { category: data } });
    }
  };

  return (
    <div className={b()}>
      <div className={b('icons')}>
        <Link to="/">
          <LogoIcon />
        </Link>
        <div className={b('social-icons')}>
          <Telegram className={b('icon')}/>
          <Youtube className={b('icon')}/>
          <Vk className={b('icon')}/>
          <Whatsapp className={b('icon')}/>
        </div>
      </div>
      <div className={b('main')}>
        <div className={b('sections')}>
          <h3 className={b('title')}>Разделы</h3>
          <ul className={b('list')}>
            {sectionItems.map((item) => (
              <li className={b('item')} key={item.key} onClick={() => handleClick(item.data)}>{item.label}</li>
            ))}
          </ul>
        </div>
        <div className={b('info')}>
          <h3 className={b('title')}>Информация</h3>
          <ul className={b('list')}>
            {infoItems.map((item) => (
              <Link to={item.path} className={b('item')} key={item.key}>{item.label}</Link>
            ))}
          </ul>
        </div>
        <div className={b('contacts')}>
          <h3 className={b('title')}>Контакты</h3>
          <ul className={b('list-contact')}>
            <a className={b('c-item')} href="tel:+79239390088">+7 923 939 00 88</a>
            <a href="mailto:work@poizonrf.ru" className={b('item')}>work@poizonrf.ru</a>
            <li className={b('mt')}>г. Москва, улица Советская, дом 18</li>
          </ul>
        </div>
      </div>
      <div className={b('rules')}>
        <div className={b('left')}>
          <span className={b('company-name')}>PoizonRF</span>
          <span>Все права защищены</span>
        </div>
        <div className={b('right')}>
          <a className={b('item')} href="#">Политика конфиденциальности  </a>
          <a className={b('item')} href="#"> Пользовательское соглашение</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
