import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from './token';

const useAuthCheck = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [warningTitle, setWarningTitle] = useState('');
  const [isModalRegister, setIsModalRegister] = useState(false);
  const [isModalLogin, setIsModalLogin] = useState(false);
  const [targetRoute, setTargetRoute] = useState('');
  const [postAuthCallback, setPostAuthCallback] = useState(null);
  const navigate = useNavigate();
  const token = getToken();

  useEffect(() => {
    if (token && postAuthCallback) {
      postAuthCallback();
      setPostAuthCallback(null);
    }
  }, [token]);

  const checkAuth = (routeOrCallback, title) => {
    if (token) {
      if (typeof routeOrCallback === 'string') {
        navigate(routeOrCallback);
      } else if (typeof routeOrCallback === 'function') {
        routeOrCallback();
      }
    } else {
      setIsModalOpen(true);
      setWarningTitle(title);
      if (typeof routeOrCallback === 'string') {
        setTargetRoute(routeOrCallback);
      } else if (typeof routeOrCallback === 'function') {
        setPostAuthCallback(() => routeOrCallback);
      }
    }
  };

  const handleRegister = () => {
    setIsModalOpen(false);
    setIsModalRegister(true);
  };

  const handleLogin = () => {
    setIsModalOpen(false);
    setIsModalLogin(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setIsModalRegister(false);
    setIsModalLogin(false);
    setTargetRoute('');
  };

  return {
    isModalOpen,
    isModalRegister,
    isModalLogin,
    warningTitle,
    checkAuth,
    handleRegister,
    handleLogin,
    handleClose,
    targetRoute,
  };
};

export default useAuthCheck;
