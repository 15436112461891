import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import bem from 'easy-bem';
import { Checkbox, Collapse, Slider } from 'antd';
import './style.scss';
import { ReactComponent as Delete } from '../../assets/icons/x-gray.svg';
import ButtonCustom from '../../ui-kit/button';
import {
  UKSize, USSize, RUSSize, EUSize, apparelSize
} from './data';
import { getBrands } from '../../models/catalog/catalogSlice';


const { Panel } = Collapse;
function Filters({
  selectedBrands,
  onChangeBrand,
  chosenSize,
  activeSizeTab,
  onChangeSizeType,
  onChangeChosenSize,
  range,
  onChangeRangeComplete,
  onChangeRange,
  handleCleanAllFilters,
  apparel,
  noSizes,
  category
}) {
  const b = bem('filters');
  const dispatch = useDispatch();
  const [brands, setBrands] = useState([]);


  const tabSize = [
    { key: '1', title: 'EU', content: EUSize },
    { key: '4', title: 'RU', content: RUSSize },
    { key: '2', title: 'US', content: USSize },
    { key: '3', title: 'UK', content: UKSize },
  ];

  const getRelativeBrands = async () => {
    try {
      if (category) {
        const response = await dispatch(getBrands(`?category=${category}`));

        setBrands(response.payload.length > 35 ? response.payload.slice(0, 35) : response.payload);
      } else {
        const response = await dispatch(getBrands());
        setBrands(response.payload.slice(0, 35));
      }
    } catch {
      throw new Error();
    }
  };

  useEffect(() => {
    getRelativeBrands();
  }, []);


  return (
    <div className={b()}>
      <ButtonCustom icon={<Delete />} className={b('delete-btn')} onClick={handleCleanAllFilters} type="text">
        Очистить все фильтры
      </ButtonCustom>

      <Collapse className={b('collapse')}>
        <Panel className={b('title')} header="Бренды" key="1">
          {brands?.map((brand) => (
            <Checkbox
              key={brand.id}
              value={brand.slug}
              checked={selectedBrands.includes(brand.name)}
              onChange={() => onChangeBrand(brand.name)}
            >
              <span className={b('brand-item')}>{brand.name}</span>
              {'  '}
              <span className={b('brand-count')}>{brand.items_count}</span>
            </Checkbox>
          ))}
        </Panel>
        {!noSizes && (
          <Panel className={b('title')} header="Размер" key="2">
            {!apparel && (
              <div className={`${b('tab-header')} ${activeSizeTab !== 'apparel' ? 'bottom-line' : ''}`}>
                {tabSize.map((tab) => (
                  <div
                    key={tab.key}
                    className={b(`tab ${activeSizeTab === tab.title ? 'active' : ''}`)}
                    onClick={() => onChangeSizeType(tab.title)}
                  >
                    {tab.title}
                  </div>
                ))}
              </div>
            )}
            <div className={b('tab-content')}>
              {!apparel && tabSize.map((tab) => (
                <div key={tab.key} className={b(`tab-pane ${activeSizeTab === tab.title ? 'active' : ''}`)}>
                  {tab.content.map((item, index) => (
                    <div
                      key={index}
                      className={b(`tab-item ${item === chosenSize ? 'choose' : ''}`)}
                      onClick={() => onChangeChosenSize(item)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              ))}
              {apparel && (
                <div className={b('apparel-pane')}>
                  {apparelSize.map((item, index) => (
                    <div
                      key={index}
                      className={b(`tab-item ${item === chosenSize ? 'choose' : ''}`)}
                      onClick={() => onChangeChosenSize(item)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Panel>
        )}
        <Panel
          className={b('title')}
          header={(
            <div className={b('box')}>
              <h1 className={b('title-single')}>Цена</h1>
              <p className={b('ellipse')} />
            </div>
          )}
          key="3"
        >
          <div className={b('range-box')}>
            <p className={b('range-from')}>
              {range[0]}
            </p>
            <p className={b('range-to')}>
              {range[1]}
            </p>
          </div>
          <Slider
            range
            step={2000}
            min={2000}
            max={300000}
            value={range}
            onChange={onChangeRange}
            onChangeComplete={onChangeRangeComplete}
            style={{ width: '100%' }}
            className={b('slider-price')}
          />
        </Panel>
      </Collapse>

      {/* <div className={b('in-stoke')}> */}
      {/*   <p className={b('in-title')}> */}
      {/*     В наличии */}
      {/*     <span className={b('in-count')}>{total}</span> */}
      {/*   </p> */}
      {/*   <Switch className={b('switch')}/> */}
      {/* </div> */}
    </div>
  );
}

export default Filters;
