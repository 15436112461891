import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken, removeToken } from '../../utils/token';
// import { setToken } from '../../utils/token';


const BASE_URL = process.env.REACT_APP_BACKEND_HOST;


const initialState = {
  // isAuth: false,
  // isLoading: false,
  // user: {},
  // phone: null,
  // token: null,
  // telegramToken: null,
  // verifyCode: false
  catalog: [],
  filteredCatalog: [],
  product:  null,
  categories: [],
  brands: [],

  isFavorite: false,
  basket: [],


  isLoading: false,
  total: null,
};


export const getCatalog = createAsyncThunk(
  'catalog/getCatalog',
  async (query, { rejectWithValue }) => {
    try {
      const url = query ? `${BASE_URL}/catalog/?${query}` : `${BASE_URL}/catalog/`;
      const response = await axios.get(url);

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const getCatalogFiltered = createAsyncThunk(
  'catalog/getCatalogFiltered',
  async (query, { rejectWithValue }) => {
    const token = getToken();
    const headers = token ? { Authorization: `JWT ${token}` } : {};
    try {
      const response = await axios.get(`${BASE_URL}/catalog/?${query}`, {
        headers
      });

      return response.data;
    } catch (e) {
      if (e.response.status === 401) {

        window.location.href = '/';
        removeToken();
      }

      return rejectWithValue(e.response.data.message);
    }
  }
);

export const getFavoriteItems = createAsyncThunk(
  'catalog/getFavoriteItems',
  async (query, { rejectWithValue }) => {
    const token = getToken();
    try {
      const response = await axios.get(`${BASE_URL}/catalog/?is_favorited=true&${query}`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      return response.data;
    } catch (e) {
      if (e.response.status === 401) {

        window.location.href = '/';
        removeToken();
      }

      return rejectWithValue(e.response.data.message);
    }
  }
);

export const getProductById = createAsyncThunk(
  'catalog/getProductById',
  async (id, { rejectWithValue }) => {
    const token = getToken();
    const headers = token ? { Authorization: `JWT ${token}` } : {};
    try {
      if (token) {
        const response = await axios.get(`${BASE_URL}/catalog/${id}/`, {
          headers
        });

        return response.data;
      }
      const response = await axios.get(`${BASE_URL}/catalog/${id}/`);

      return response.data;
    } catch (e) {
      if (e.response.status === 401) {

        window.location.href = '/';
        removeToken();
      }

      return rejectWithValue(e.response.data.message);
    }
  }
);

export const getBrands = createAsyncThunk(
  'catalog/getBrands',
  async (query, { rejectWithValue }) => {
    try {
      const url = query ? `${BASE_URL}/brands/${query}` : `${BASE_URL}/brands/`;

      const response = await axios.get(url);

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getCategoryName = createAsyncThunk(
  'catalog/getCategoryName',
  async (query, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/category/${query}`);

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getCategories = createAsyncThunk(
  'catalog/getCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/category/`);

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const addFavoriteItem = createAsyncThunk(
  'catalog/addFavoriteItem',
  async (id, { rejectWithValue }) => {
    const token = getToken();
    try {
      const response = await axios.post(`${BASE_URL}/catalog/${id}/favorite/`, id, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      return response.data;
    } catch (e) {
      if (e.response.status === 401) {

        window.location.href = '/';
        removeToken();
      }

      return rejectWithValue(e.message);
    }
  }
);

export const deleteFavoriteItem = createAsyncThunk(
  'catalog/deleteFavoriteItem',
  async (id, { rejectWithValue }) => {
    const token = getToken();
    try {
      const response = await axios.delete(`${BASE_URL}/catalog/${id}/favorite/`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const findByLink = createAsyncThunk(
  'catalog/findByLink',
  async (link, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/poizon/item_info_by_link/`, { link: link });

      console.log(response, 'from func');
      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getPopularItems = createAsyncThunk(
  'catalog/getPopularItems',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/catalog/popular_search/`);

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getCarouselData = createAsyncThunk(
  'catalog/getCarouselData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/carousel/`);

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);


const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setProduct(state, action) {
      state.product = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCatalog.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      getCatalog.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.catalog = [...state.catalog, ...action.payload.results];
      }
    );
    builder.addCase(
      getCatalogFiltered.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.filteredCatalog = action.payload.results
          ? [...state.filteredCatalog, ...action.payload.results]
          : [...state.filteredCatalog, action.payload];
      }
    );
    builder.addCase(
      getCatalog.rejected,
      (state, action) => {
        state.isLoading = false;
        console.error('Ошибка при получении каталога:', state.catalog, action.error);
      }
    );
    builder.addCase(
      getProductById.pending,
      (state) => {
        console.log(state.isLoading, 'from pending');
        state.isLoading = true;
      }
    );
    builder.addCase(
      getProductById.fulfilled,
      (state, action) => {
        console.log(state.isLoading, 'from fulfiled');
        state.isLoading = false;
        console.log(state.isLoading, 'from fulfiled');
        state.product = action.payload;
      }
    );
    builder.addCase(
      getProductById.rejected,
      (state, action) => {
        state.isLoading = false;
        console.error('Ошибка при получении продукта:', state.product, action.error);
      }
    );
    builder.addCase(
      getBrands.fulfilled,
      (state, action) => {
        state.brands = action.payload;
      }
    );

    builder.addCase(
      getCategories.fulfilled,
      (state, action) => {
        state.categories = action.payload;
      }
    );
    builder.addCase(
      addFavoriteItem.fulfilled,
      (state, action) => {
        state.isFavorite = true;
      }
    );
    builder.addCase(
      deleteFavoriteItem.fulfilled,
      (state, action) => {
        state.isFavorite = false;
      }
    );
  }
});

export const { setProduct } = catalogSlice.actions;
// export const { setUser } = catalogSlice.actions;

export default catalogSlice.reducer;
