import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import bem from 'easy-bem';
import { Spin } from 'antd';
import { ReactComponent as SneakerWhite, ReactComponent as SneakerWhiteM } from '../../assets/icons/sneakerWhite.svg';

import { ReactComponent as SneakerBlue, ReactComponent as SneakerBlueM } from '../../assets/icons/sneakerBlue.svg';

import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';


import './style.scss';
import InputCustom from '../../ui-kit/input';
import { findByLink, setProduct } from '../../models/catalog/catalogSlice';


function SearchByLink({ light, className }) {
  const b = bem('search');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);

  const extractUrl = (text) => {
    const urlPattern = /https:\/\/[^\s]+/;
    const match = text.match(urlPattern);

    return match ? match[0] : '';
  };

  const handleSearch = (value) => {
    setSearch(extractUrl(value));
    setNotFound(false);
  };

  const handleFind = async () => {
    try {
      setLoading(true);
      const result = await dispatch(findByLink(search));

      if (result.error) {
        setNotFound(true);
        setLoading(false);
      } else {
        if (result.payload.skus.length === 0) {
          setNotFound(true);
        } else {
          setNotFound(false);

          dispatch(setProduct(result.payload));
          navigate(`/products/${result.payload.id}`, { state: { fromSearchByLink: true } });
        }

        setLoading(false);
      }
    } catch (error) {
      setNotFound(true);
      setLoading(false);
    }
  };

  const combinedClassName = `${b()} ${className || ''}`;

  return (
    <div className={combinedClassName} style={{ backgroundColor: light ? '#7364FF' : '#0F0F11' }}>
      <div className={b('content')}>
        {light ? <SneakerWhiteM className={b('sneaker-top')} /> : <SneakerBlueM className={b('sneaker-top')} /> }
        <div className={b('text')}>
          <h2 className={b('title')}>Не нашли нужный товар?</h2>
          <p className={b('subtitle')}>
            Вы можете воспользоваться поиском по ссылке
            <br />
            и мы добавим нужный товар
          </p>
        </div>
        {light ? <SneakerWhite className={b('sneaker')} /> : <SneakerBlue className={b('sneaker')} /> }

        <div className={b('search-input')}>
          <InputCustom
            prefix={<SearchIcon />}
            suffix={search
              ? (
                <span className={b('find')} onClick={handleFind}>
                  {loading ? <Spin className={b('spin')} /> : 'Найти'}
                </span>
              )
              : null}
            name="link"
            type="form"
            placeholder="Вставьте ссылку на товар с Poizon.."
            className={
              `${b('input')} ${notFound ? b('input notFound') : 'input'} ${search ? b('input typing') : 'input'}`
            }
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <div className={b('bottom')}>
            <p className={b('where')} onClick={() => navigate('/how-to-copy-link')}>Где найти ссылку?</p>
            {notFound && <p className={b('title-notFound')}>Товар не найден</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchByLink;
