import React from 'react';
import bem from 'easy-bem';
import './style.scss';

// в будущем страница для 404
function NotFound() {
  const b = bem('not');

  return (
    <div className={b()}>
      <h1 className={b('title')}>СТРАНИЦА В РАЗРАБОТКЕ...</h1>
    </div>
  );
}

export default NotFound;
