import React, { useEffect, useState } from 'react';
import bem from 'easy-bem';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CustomTabs from '../../ui-kit/tabs';

import './style.scss';
import { getOrdersList } from '../../models/order/orderSlice';
import OrderItem from '../../ui-kit/orderItem';
import OrderFullInfo from '../order';
import { Spin } from 'antd';


function OrderList() {
  const b = bem('orders');
  const dispatch = useDispatch();
  const [ordersList, setOrdersList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);
  const { orders } = useSelector((state) => state.order);
  const [currentOrder, setCurrentOrder] = useState([]);
  const [fullInfo, setFullInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const tabs = [
    {
      title: 'Оформленные',
      key: '1',
      content: ordersList.filter(order =>
        order.status === 'AWAITING_PAYMENT' ||
        order.status === 'FAILED_PAYMENT' ||
        order.status === 'PAID' ||
        order.status === 'SPLIT_PAID' ||
        order.status === 'AWAITING_RECEIPT' ||
        order.status === 'AWAITING_SPLIT_PAID'
      )
    },
    {
      title: 'В процессе',
      key: '2',
      content: ordersList.filter(order =>
        order.status === 'PROCESSING' ||
        order.status === 'AWAIT_DELIVERED' ||
        order.status === 'ACCEPT_DELIVERY' ||
        order.status === 'ON_THE_ROAD'
      )
    },
    {
      title: 'Завершённые',
      key: '3',
      content: ordersList.filter(order =>
        order.status === 'COMPLETED' ||
        order.status === 'CANCEL'
      )
    }
  ];

  useEffect(() => {
    const getAllOrders = async () => {
      setLoading(true);
      try {
        const response = await dispatch(getOrdersList());
        setOrdersList(response.payload);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    getAllOrders();

  }, []);

  useEffect(() => {
    if (tabs.length > 0) {
      setSelectedTab(tabs[0]);
    }
  }, [ordersList]);

  const handleChangeTab = (tab) => {
    setSelectedTab(tab);
  };

  const handleOpenFullPage = (item) => {
    setCurrentOrder(item);
    setFullInfo(true);
  };

  // useEffect(() => {
  //   if (location.state === 'open-last') {
  //     handleOpenFullPage(orders[0]);
  //   }
  // },[location]);

  const returnToOrderList = () => {
    setFullInfo(false);
    setCurrentOrder([]);
  };
  console.log(selectedTab);


  return (
    <div className={b()}>
      <div className={b('menu')}>
        <CustomTabs />
      </div>
      <div className={b('content')}>
        <div className={b('top-tab')}>
          <h2 className={b('title')}>Список заказов</h2>
          <div className={b('tabs')}>
            {tabs.map((item, index) => (
              <div
                className={selectedTab?.key === item?.key ? `${b(`tab-${index}`)} active` : b(`tab-${index}`)}
                key={item.key}
                onClick={() => handleChangeTab(item)}
              >
                {item.title}
              </div>
            ))}
          </div>
        </div>
        <div className={b('orders-list')}>
          {loading ? (
            <div className={b('loading-box')}>
              <Spin size="large"/>
            </div>
          ) : selectedTab && selectedTab.content.length > 0 ? (
            selectedTab.content.map((content) => (
              <OrderItem
                key={content.id}
                onClick={handleOpenFullPage}
                item={content}
              />
            ))
          ) : (
            <h1 className={b('not')}>{`Нет заказов со статусом "${selectedTab?.title}"`}</h1>
          )}
        </div>
        {/* {fullInfo && currentOrder ? ( */}
        {/*     <OrderFullInfo */}
        {/*       order={currentOrder} */}
        {/*       onClose={returnToOrderList} */}
        {/*     /> */}
        {/*   ) */}
        {/*   : ( */}
        {/*     <> */}
        {/*       <div className={b('top-tab')}> */}
        {/*         <h2 className={b('title')}>Список заказов</h2> */}
        {/*         <div className={b('tabs')}> */}
        {/*           {tabs.map((item, index) => ( */}
        {/*             <div */}
        {/*               className={selectedTab?.key === item?.key ? `${b(`tab-${index}`)} active` : b(`tab-${index}`)} */}
        {/*               key={item.key} */}
        {/*               onClick={() => handleChangeTab(item)} */}
        {/*             > */}
        {/*               {item.title} */}
        {/*             </div> */}
        {/*           ))} */}
        {/*         </div> */}
        {/*       </div> */}
        {/*       <div className={b('orders-list')}> */}
        {/*         {loading ? ( */}
        {/*           <div className={b('loading-box')}> */}
        {/*             <Spin size="large"/> */}
        {/*           </div> */}
        {/*         ) : selectedTab && selectedTab.content.length > 0 ? ( */}
        {/*           selectedTab.content.map((content) => ( */}
        {/*             <OrderItem */}
        {/*               onClick={handleOpenFullPage} */}
        {/*               item={content} */}
        {/*             /> */}
        {/*           )) */}
        {/*         ) : ( */}
        {/*           <h1 className={b('not')}>{`Нет заказов со статусом "${selectedTab?.title}"`}</h1> */}
        {/*         )} */}
        {/*       </div> */}
        {/*     </> */}
        {/*   )} */}

      </div>
    </div>
  );
}

export default OrderList;
